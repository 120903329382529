import React from 'react';
import {Container, Col, Row, Card, CardBody, Button} from "reactstrap";
import { MailIcon } from "@primer/octicons-react";

import SectionTitleRow from "../shared/SectionTitleRow";

import needAChange from "../../static/images/need-a-change.jpg";
import frustrated from "../../static/images/frustrated.jpg";
import promotions from "../../static/images/promotions-passed-you-by.jpg";
import expectations from "../../static/images/expectations.jpg";
import worklifebalance from "../../static/images/work-life-balance.jpg";
import dosomethingdifferent from "../../static/images/do-something-different.jpg";
import GetInTouchButton from "./GetInTouchButton";
import Divider from "../shared/Divider";

const AboutYou = () => {
  return (
    <Container>
      <a className="ccc-anchor" id="aboutYou" />
      <SectionTitleRow>About you</SectionTitleRow>
      <Row>
        <Col xs={12}>
          <p>
            As a woman, you have been feeling stuck or overwhelmed for a while.
            You are drained by internal conflict and wish you could reclaim some freedom.
            You want to make choices with clarity and confidence.
            You want to be seen and heard, but without judgement.
            You know you have something within you to ignite and really want to thrive on it.
            You’re curious about what could have been or could still be for you.
          </p>
          <p>
            Deep inside, you know that investing in yourself is the answer, but until now something has held you back.
            However, you are finally here, looking for support and willing and ready to act to make what you really want actually happen.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={4} className="my-3">
          <Card>
            <img src={needAChange} className="w-100" alt="Woman looking at clothes in wardrobe" />
            <CardBody className="text-center">
              Maybe you know you need a change, but just don't know what?
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4} className="my-3">
          <Card>
            <img src={frustrated} className="w-100" alt="Woman holding head in her hands" />
            <CardBody className="text-center">
              Maybe you're frustrated by your lack of energy and focus?
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4} className="my-3">
          <Card>
            <img src={promotions} className="w-100" alt="Woman in office looking unhappy while others are happy in the background" />
            <CardBody className="text-center">
              Maybe despite all your efforts promotions have passed you by?
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4} className="my-3">
          <Card>
            <img src={expectations} className="w-100" alt="Woman standing at fence in forest" />
            <CardBody className="text-center">
              Maybe circumstances, beliefs and expectations have got in your way?
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4} className="my-3">
          <Card>
            <img src={worklifebalance} className="w-100" alt="Man and woman sitting on floor surrounded by children's toys" />
            <CardBody className="text-center">
              Maybe work-life balance is a distant memory?
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4} className="my-3">
          <Card>
            <img src={dosomethingdifferent} className="w-100" alt="Woman about to have long hair cut off" />
            <CardBody className="text-center">
              Maybe you crave doing something very different but are too scared to try?
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="text-center">
            Whatever your reasons for feeling stuck or overwhelmed, I can help you to get ahead without sacrificing your family or well-being.
          </p>
        </Col>
      </Row>
      <GetInTouchButton />
      <Divider />
    </Container>
  )
}

export default AboutYou;