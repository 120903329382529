import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Chart from "./Chart";
import '../../../onboarding.css';
import {Button, Col, Container, Row, Alert, Form} from "reactstrap";
import WheelOfLifeCategoryInput from "./WheelOfLifeCategoryInput";
import {Link, useNavigate} from "react-router-dom";
import {colours} from "../../../config";
import BlueBackground from "../../shared/BlueBackground";
import SimpleHeader from "../../shared/SimpleHeader";
import {setWheelOfLifeImageData} from "../../../features/pageSlice";

const WheelOfLife = () => {
  const wheelOfLife = useSelector((state) => state.wheelOfLife);
  const [complete, setComplete] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let isComplete = true;
    wheelOfLife.map(category => {
      if (category.score === null)
        isComplete = false;
    })
    setComplete(isComplete);
  }, [ wheelOfLife ])

  const processChartData = () => {
    if (complete) {
      setShowChart(true);
      setShowAlert(false);
    }
    else {
      setShowAlert(true)
    }
    let processedChartData = [];
    wheelOfLife.forEach(category => {
      processedChartData.push({
        subject: category.name,
        A: category.score,
        B: 10
      })
    });
    setChartData(processedChartData);
  }

  const saveAndStartDISCProfile = () => {
    localStorage.setItem('wheelOfLife', JSON.stringify(wheelOfLife))

    const svgElement = document.querySelector('.recharts-surface');

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);
    const base64 = btoa(svgString)
    const dataURL = `data:image/svg+xml;base64,${base64}`;

    console.log(dataURL);

    dispatch(setWheelOfLifeImageData(dataURL));

    navigate('/disc')
  }

  return (
    <React.Fragment>
      <BlueBackground />
      <Container
        fluid
        style={{backgroundColor: colours.prussianBlue, color: colours.deepChampagne, height: "100%"}}
      >
        <SimpleHeader title="Wheel of Life" />
        {
          showChart === false
          &&
          <React.Fragment>
            <Row>
              <Col xs={12} className="p-4 d-flex justify-content-center">
                <div style={{maxWidth:"800px"}}>
                  <h5 className="text-center">
                    Choose your life categories
                  </h5>
                  <p>
                    Consider what categories make up a satisfying and balanced life for you.
                    Some categories are suggested below - 'Partner', 'Friends', 'Family' etc.
                    You can use these if they feel right, or you can re-label some of these categories with whatever feels more relevant to you.
                  </p>
                  <h5 className="text-center">
                    Rate each category
                  </h5>
                  <p>
                    Please choose a number which represents your <strong>CURRENT</strong> level of satisfaction with each category, where <span className="text-decoration-underline">0 is completely unsatisfied</span> and 10 is fully satisfied.
                    Use the first number that pops into your head as this is likely to be more accurate.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-center">
                  <Form>
                    {
                      wheelOfLife.map(item => {
                        return (
                          <WheelOfLifeCategoryInput key={item.id} item={item} />
                        )
                      })
                    }
                  </Form>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                {
                  showAlert === true
                  &&
                  <Alert color="warning">
                    Please choose a number for all categories.
                  </Alert>
                }
              </Col>
              <Col sm={12} className="text-center">
                <Button className="mx-4" onClick={() => navigate('/onboarding')}>Back</Button>
                <Button onClick={processChartData}>Next</Button>
              </Col>
            </Row>
          </React.Fragment>
        }
        {
          showChart === true
          &&
          <React.Fragment>
            <Row>
              <Col sm={12} className="d-flex justify-content-center">
                <Chart chartData={chartData} />
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="text-center">
                <p>
                  Next, please complete the DISC behavioural profile.
                </p>
                <Button className="mx-4" onClick={() => setShowChart(false)}>Back</Button>
                <Button onClick={saveAndStartDISCProfile}>Save and start DISC Profile</Button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </Container>
    </React.Fragment>

  );
}

export default WheelOfLife;
