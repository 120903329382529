import React from 'react';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from "recharts";

const Chart = props => {

  return (
    <RadarChart
      cx="50%"
      cy="50%"
      outerRadius="80%"
      width={700}
      height={700}
      data={props.chartData}
      margin={{top: 0, right: 60, bottom: 0, left: 60}}
    >
      <PolarGrid
        gridType="polygon"
      />
      <PolarAngleAxis
        dataKey="subject"
      />
      <PolarRadiusAxis
        domain={[0, 10]}
        orientation="middle"
        tickCount={10}
      />
      <Radar
        name="WheelOfLife"
        dataKey="B"
        stroke="#f7d490"
        fill="#f8e4bf"
        fillOpacity={0.25}
        animationDuration={650}
      />
      <Radar
        name="WheelOfLife"
        dataKey="A"
        stroke="#c49c50"
        fill="#f7d490"
        fillOpacity={0.7}
        animationDuration={500}
      />
    </RadarChart>
  )
}

export default Chart;