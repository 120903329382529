import React, { useEffect } from "react";
import {useSelector} from "react-redux";
import { Container } from "reactstrap";

import '../../../onboarding.css';
import StartPage from "./StartPage";
import RowInput from "./RowInput";
import Results from "./Results";
import BlueBackground from "../../shared/BlueBackground";
import SimpleHeader from "../../shared/SimpleHeader";
import {colours} from "../../../config";

const DISCProfile = () => {
  const currentRowIndex = useSelector((state) => state.page.currentRowIndex);

  return (
    <React.Fragment>
      <BlueBackground />
      <Container
        fluid
        style={{backgroundColor: colours.prussianBlue, color: colours.deepChampagne, height: "100%"}}
      >
        <SimpleHeader title="Your DISC behavioural profile" />
        {
          currentRowIndex === null
          ?
          <StartPage />
          :
          currentRowIndex === 'finished' ? <Results /> : <RowInput />
        }
      </Container>
    </React.Fragment>
  );
}

export default DISCProfile;
