import React from 'react';
import { Container, Row, Col } from "reactstrap";
import SectionTitleRow from "../shared/SectionTitleRow";
import Divider from "../shared/Divider";
import ButterflyBulletPointRow from "../shared/ButterflyBulletPointRow";
import SubtitleRow from "../shared/SubtitleRow";
import Quote from "../shared/Quote";
import GetInTouchButton from "./GetInTouchButton";

const ReviveAndThrive = () => {
  return (
    <Container>
      <SectionTitleRow>What is coaching?</SectionTitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Coaching is a personalised and transformative service which involves thought-provoking conversations
            and exercises to unlock your potential and maximise your performance in life. It helps you to:
          </p>
        </Col>
      </Row>
      <ButterflyBulletPointRow label="Gain a much deeper understanding of you, your situation and your vision for the future." />
      <ButterflyBulletPointRow label="Challenge the unhelpful thoughts, feelings and habits that hold you back from achieving your personal and professional ambitions." />
      <ButterflyBulletPointRow label="Reconnect with hidden strengths to overcome obstacles." />
      <ButterflyBulletPointRow label="Shine a light on other perspectives to increase opportunity and choice." />
      <ButterflyBulletPointRow label="Take responsibility and focused action to grow." />
      <ButterflyBulletPointRow label="Hold yourself to account." />
      <ButterflyBulletPointRow label="Enjoy your efforts, recognise your learnings and celebrate your achievements." />

      <Row>
        <Col xs={12}>
          <p>
            The positive change is created gradually so that it can be sustained.
            Some of these changes will be visible to others and some just within yourself, but the change doesn’t need to be large to have a large impact on your life.
          </p>
        </Col>
      </Row>

      {/*<SubtitleRow>How exactly will this work?</SubtitleRow>*/}
      {/*<Row>*/}
      {/*  <Col xs={12}>*/}
      {/*    <p>*/}
      {/*      Our private and confidential fortnightly sessions are 60-90 minutes long and can take place via telephone, video call or in person at times convenient to you including evenings and weekends.*/}
      {/*      During these sessions we will review where you are now, where you want to be, and how you can get there using the 7 steps above.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      The 7 steps are addressed in a seamless and dynamic way in every session so that the positive change you want for yourself is created gradually and can be sustained.*/}
      {/*      Some of these changes will be visible to others and some just within yourself, but the change doesn’t need to be large to have a large impact on your life.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      You will leave each session with a challenging but empowering action plan which you are comfortable with and motivated by, but most importantly, will not sacrifice your family or well-being.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      After each session you will also receive:*/}
      {/*    </p>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*<ButterflyBulletPointRow label="A detailed summary to help consolidate your progress. This is not a common practice among coaches, but as a former doctor, thorough record keeping and transparency is very important to me when providing a service to others." />*/}
      {/*<ButterflyBulletPointRow label="A self-assessment worksheet to use between sessions to help hold you to account, analyse your progress and prepare you for your next step." />*/}

      <SubtitleRow>Investing in yourself</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Investing in others in terms of your time, money or energy is a cost, but investing in yourself in the same way is an opportunity.
            Women who thrive in life invest in themselves just as easily as they do in others.
          </p>
          <p>
            As difficult as this may sound, particularly for mums, sometimes you need to invest in yourself <em>first</em>, like on a flight when you have to secure your own oxygen mask before assisting anyone else.
            Imagine the benefits to you and those around you if you just chose to invest in yourself before others?
          </p>
          <p>
            Magic potions don’t exist but the right coach for you does.
            Of course it’s possible to achieve positive change without a coach and many people do, but usually not as efficiently or as effectively.
            How have your own efforts served you so far?
          </p>
          <p>
            It’s a false economy to choose not to see alternative perspectives, not to be held to account and not to identify and learn from mistakes earlier.
            Investing in yourself is simply a matter of choice and those that get ahead choose coaching with conviction.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Quote
            content="“Faith is taking the first step even when you don’t see the whole staircase.”"
            attribution="Martin Luther King Jr"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="mb-4">
            A carefully considered dream can become a reality, but knowledge without action is useless.
            Coaching helps you to take inspired action so why not take a leap of faith and get in touch?
          </p>
        </Col>
      </Row>

      <GetInTouchButton />

      <Divider />
    </Container>
  )
}

export default ReviveAndThrive;