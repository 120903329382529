import React from 'react';
import { Container, Row, Col } from "reactstrap";
import SectionTitleRow from "../shared/SectionTitleRow";
import Divider from "../shared/Divider";
import TestimonialRow from "../shared/TestimonialRow";
import GetInTouchButton from "./GetInTouchButton";

const SelectedTestimonials = () => {
  return (
    <Container>
      <a className="ccc-anchor" id="selectedTestimonials" />
      <SectionTitleRow>Selected Testimonials</SectionTitleRow>
      <TestimonialRow attribution="One-to-one client">
        “I met with Sally as I was lacking confidence in my work life. I needed a new challenge but wasn’t at all confident in attending interviews, didn’t like ‘blowing my own trumpet’, therefore I wasn’t being offered the jobs I applied for. Sally put me at ease and made me feel comfortable discussing what for me was quite a difficult subject. She asked me questions that really made me stop and think not only about my job situation but also about me as a person, giving me plenty of thinking time before I needed to answer. The questions seemed to be delivered with great thought and insight. After each session Sally recapped by emailing me a written summary which also gave us a base for the next session. I left each session feeling motivated, more positive and more confident, which was a surprise for me. If I’m honest I wasn’t really expecting the sessions to make much of a difference to my mindset. I understand myself a lot better and I know where my hesitancy of admitting I can do a job well has come from. I walked into my next interview confident in what I was wearing, prepared for what I would say and relaxed. I am very pleased to say I was offered the job and the new challenge has begun. I can’t thank Sally enough for the coaching she gave me. I feel I have grown as a person because of it.”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “I would describe the group coaching
        as well structured, thorough but also
        supportive and compassionate. That
        it comes from a deeply authentic and
        expert place.

        I would say that Sally’s strengths lie in
        being able to understand the group
        participants on quite a meaningful
        level and being able to provide
        extremely relevant guidance. Sally
        was able to convey interesting and
        impactful ideas clearly, ask powerful
        questions and provide constructive
        and thought-provoking feedback and
        exercises.

        The outcome for me was that Sally
        was highly skilled in creating a safe
        and encouraging space for us to
        explore challenges, set goals, and
        work towards achieving them. It was
        amazing to be able to see, in real
        time, the ways in which our progress
        was motivating each other, and
        contributing to our collective growth.”
      </TestimonialRow>
      <TestimonialRow attribution="One-to-one client">
        “Coaching has unexpectedly changed all aspects of my life: I have been having monthly coaching with Sally for the last eight months and I feel positive shifts everywhere because my issues, both conscious and unconscious, are closely tangled together. It has been like a domino effect: you kick one but it triggers a chain reaction. Sally is a great professional who has helped me in this journey not by giving all the answers but by encouraging me to find them, so that I can keep the joy of discovery to myself. The exercises we did and the techniques we tried might have looked insignificant on their own, but it is all the little changes that they implemented in my life that made the big difference. I have enjoyed my face-to-face interactions with Sally. By giving me the space to be honest with myself and to challenge myself, she has helped me to believe in myself, break stereotypes and reveal who I really am.”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “The discussions were deep diving
        and revealed a real sense of collective
        understanding. Sally listens,
        responds creatively by drawing on a
        fund of knowledge, and offers aids to
        addressing the inherent issues. Her
        rate of learning about the nature of the
        group was remarkable.

        As a result of coaching I’ve identified,
        named and plotted my plans, which
        has helped me to replace fear with
        hope. This experience will reverberate
        for a long time.”
      </TestimonialRow>
      <TestimonialRow attribution="One-to-one client">
        “Sally was a breath of fresh air. Her understanding of what I want and needed was exceptional. I had reached my intended goal within two weeks of working with Sally, however, she helped me to go beyond my initial goal and to see a bigger future that I could achieve. After 3 months I felt more confident to speak about who I am. She did this while being sensitive to my neurological needs.”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “By far the biggest impact of Sally's group coaching has been on
        my self-awareness. I’m not blind to my personality
        or traits but life can get in the way.

        Having time focused on exploring myself with a
        professional and a like-minded group was just
        what I needed. It helped me gain confidence and
        provided me with a personalised plan of action.”
      </TestimonialRow>
      {/*<TestimonialRow>*/}
      {/*  “You are amazing Sally and as a parent I can wholeheartedly recommend you as someone who cares and turns her knowledge into brilliant practice. You have helped our 10-year-old with her anxiety and anger issues so much and helped me as Mum to understand her behaviour better. Our house is far more harmonious now.”*/}
      {/*</TestimonialRow>*/}
      <TestimonialRow attribution="One-to-one client">
        “The sessions with Sally helped me to ‘step outside myself’ to see the opportunities to improve my performance and to reduce frustrations related to matters/issues outside of my direct control. Sally has enabled me to stop worrying about details, to accept lower but still satisfactory standards of others. In addition, rather than be frustrated, I have recognised my particular attention to detail traits which I have shared with my peers and subordinates with proactive humour. This has actually got them to see the benefits of the ‘right first time every time’ way I have of working with encouragement rather than my previous micro-management. The task-oriented aspect of my approach in the workplace has become less dominant as I have nurtured the people-focused element. I now realise I can get my perfectionist fix internally instead. The coaching has given me a more strategic perspective ‘big picture thinking’ approach to my work and home life. Having been through the process I personally believe that independent performance coaching can benefit anyone regardless of who they are or what they have achieved. There is always room for improvement!”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “Sally's group coaching programme was invaluable.
        I have taken part in other courses that were more
        business-minded that I have forgotten already, but
        this one has given me tools that I feel confident to
        continue using and I know they will continue my
        growth over time.

        I enjoyed meeting and sharing with others in the
        group, and interacting with Sally and learning from
        her powerful teachings. It was a relaxed and inviting
        group that pushed you to really consider your
        own self-worth and what you need, but always in
        a nurturing way. I felt comfortable enough to fully
        engage and be open.

        As a result of coaching, what I thought would take
        a year was achieved in just a few weeks, and
        journaling has become a new joyous thing I do
        daily. I now find it easier to see the positives, or what
        I need to turn something around or work around it.
        I am feeling so much more confident with my work
        and myself!”
      </TestimonialRow>
      <TestimonialRow attribution="One-to-one client">
        “Prior to Sally’s coaching sessions, I had become withdrawn and quite solitary. The thought of coaching did not particularly enthuse me, however, I recognised that I did need help in reversing the negativity that I had wrapped myself in. Although the first session was painful, the immediate impact was tangible. Sally helped me to see that there were ways in which I could break the negativity that was holding me down. I soon realised that I held the key to my future. Following the coaching sessions, I have realised many of my goals, not all in full, however, I feel well equipped to maintain my momentum and positivity towards fully achieving them. The 2 biggest benefits from Sally’s coaching have been a resurgence of my self-esteem and a more positive and happier approach to my short and long-term thoughts; points that have not gone unnoticed within my family. Without doubt, I have gone from sceptic to convert and would thoroughly recommend coaching with Sally.”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “Sally took us all very seriously and
        was committed to getting to know
        us. I liked that she challenged us
        when we needed to be challenged,
        but without being mean or babying
        us. She guided us in asking
        ourselves important questions
        about what we want, why we want
        it, how we think and how to move
        forward with purpose. She helped
        us get to know ourselves better and
        be more organised in making what
        we want to happen a reality.

        Being able to vocalise what values
        drive me in my work has made
        me feel better about the life I have
        and the sort of work I make. I have
        increased insight into how I work
        which I find very valuable as I try to
        do so in a more sustainable way.
        Doing this programme alongside
        others has allowed me to
        see what's different about my own
        practice and to benefit from their
        honesty.”
      </TestimonialRow>
      <TestimonialRow attribution="One-to-one client">
        “The coaching sessions with Sally were immensely valuable as they helped me to understand my situation and mindset more clearly. I was able to rationalise a large number of competing items on my ‘to-do’ list by identifying clear long-term goals and then committing only to those actions which would move me closer to my goals. Gaining my postgraduate qualification was particularly impactful as my new skills allowed me to perform my job with greater confidence and effectiveness.”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “Group coaching helped me to see a route forward and Sally was the ideal coach - just the right balance of encouragement and challenge.
        I always felt very safe opening up in the sessions, and I enjoyed exploring my thoughts and feelings with others on a similar journey.
        Sally is so knowledgeable in her field, always had great resources and examples to point to, and she made sure everyone's voice was heard.
        She gave us some great exercises to do that were nice and gentle, but they had a lot of effect, as they helped me to feel and say some things about myself that hadn’t seemed quite possible before.
        I’ve realised that making small changes and taking time to reflect can have the biggest impact on how I feel about my life and about myself.
        I have also been given the tools I need to talk to my colleagues and adapt my working life.
        I can’t say enough how great this experience has been and how lucky I feel.”
      </TestimonialRow>
      <TestimonialRow attribution="One-to-one client">
        “The coaching provided by Sally has enabled me to take a look at myself and in doing so I felt empowered to make the changes required to improve. Whilst I have always been confident briefing senior managers, I always felt junior to them. Through my sessions with Sally it became apparent this was only my perception and indeed I was equal to them, the result of which is I am far more confident dealing with them. During the sessions with Sally I found myself comfortably talking about things I have never discussed with anyone else before. I thank Sally for taking me down this road of discovery and I would recommend such coaching to all no matter what your background may be.”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “I came to the group coaching process wanting to experience change, make new connections, revitalise some stagnant parts of my thinking and benefit from the external viewpoint of other professionals.
        It has been very useful to listen to others’ experiences and hear how they function in their own unique contexts, and to be reminded that everyone has their struggles.
        I enjoyed being a witness to other people’s self-discovery and to be part of a shared journey with them.
        The insights from coaching have helped me establish boundaries and bring goals to a clearer focus.
        I feel more hopeful and determined for the future than I have done in several years, and the tools that we were given have proved valuable in reassessing the norms I have accepted in my life.
        Sally is empathetic, deeply knowledgeable and diligent.
        Her approach is illuminated with current insights from neuroscience and behavioural psychology, and her understanding of basic human biology grounds the work in physical reality.
        She works with honesty, humour and personal integrity.”
      </TestimonialRow>
      <TestimonialRow attribution="One-to-one client">
        “Once I had my first session I was impressed with how easy achieving improvements could be when all that really happened was Sally enabling me to think about things simply, but in greater depth, while giving support to understand my thoughts. Coaching has helped me achieve a happier home life with my family and that is a positive impact we are all thankful for.”
      </TestimonialRow>
      <TestimonialRow attribution="Group coaching client">
        “I set out to make a distinction between my work ‘success’ and my own self-worth.
        Coaching gave me insight into my own behaviour, habits and thought processes in a way which felt completely non-judgemental, and most importantly, I was supported to change these if and how I wanted to.
        I’ve had some of this behaviour for such a long time I didn’t even notice how much it was negatively impacting my life. This journey has completely changed how I think about and treat myself and others, and how I make decisions. I’ve been able to set boundaries successfully without feeling guilty for the first time in my life and it feels incredible. This has been utterly transformational.
        Sally focused on the things we brought to the session and made it specific to what we were going through at that point.
        It was a privilege to be in a supportive and challenging environment with incredible people who encourage you to take chances and move towards a more aligned way of living.
        We laughed and cried together and shared a sense of vulnerability which I’ve never experienced so quickly.
        This group coaching course has changed everything for me”
      </TestimonialRow>
      <TestimonialRow attribution="One-to-one client">
        “Prior to my sessions with Sally, I was feeling incredibly stuck with my career and struggling to find direction. Over the course of our sessions, Sally asked me many questions which challenged my thinking and facilitated identification of the blocks that were holding me back. I was then able to work with Sally to clarify my career goals. Working with Sally also held me accountable which was sometimes uncomfortable but ultimately, entirely necessary! Having completed the sessions, I have a much stronger sense of purpose and feel confident that I’ll reach the goals which I’ve set. I found Sally’s approach extremely effective and would definitely reach out to Sally for any future coaching needs.”
      </TestimonialRow>

      <GetInTouchButton />

      <Divider />
    </Container>
  )
}

export default SelectedTestimonials;