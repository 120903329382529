import React from 'react';
import {Col, Row} from "reactstrap";

const SubtitleRow = props => {
  return (
    <Row>
      <Col xs={12}>
        <h4 className="w-100 text-center my-4">
          {props.children}
        </h4>
      </Col>
    </Row>
  )
}

export default SubtitleRow;