import React from "react";
import {Button, Col, FormGroup, Input, Label} from "reactstrap";
import logo from "../../../static/images/logo.png";
import { colours } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentQuestionnairePage, setQuestionnaireAnswer} from "../../../features/pageSlice";
import {DesktopDownloadIcon, DownloadIcon, MoveToBottomIcon} from "@primer/octicons-react";

const ratingButtonOptions = [1,2,3,4,5,6,7,8,9,10];

const Question = props => {
  const dispatch = useDispatch();
  const currentAnswers = useSelector((state) => state.page.questionnaireAnswers);

  const getButtonClassName = value => {
    if (Number(currentAnswers[props.id]) === Number(value))
      return 'mx-1 my-1 btn-sm btn-active';
    else
      return 'mx-1 my-1 btn-sm btn-primary';
  }

  let question = (
    <Input
      type={props.type}
      name={props.name}
      id={props.id}
      value={currentAnswers[props.id]}
      onChange={e => dispatch(setQuestionnaireAnswer({question: props.id, answer: e.currentTarget.value}))}
    />
  );

  if (props.type === 'rating') {
    question = (
        <div className="text-center">
          {
            ratingButtonOptions.map(rating => {
              return (
                <Button
                  key={`${props.id}-${rating}`}
                  className={getButtonClassName(rating)}
                  onClick={() => dispatch(setQuestionnaireAnswer({question: props.id, answer: rating}))}
                >
                  {rating === 1 ? '1 - lowest' : (rating === 10 ? '10 - highest' : rating)}
                </Button>
              )
            })
          }
        </div>
    )
  }

  return (
    <React.Fragment>
      <FormGroup className="my-4 p-3" style={{backgroundColor: colours.cornSilk, color: colours.prussianBlue, borderRadius: '10px'}}>
        <Label for={props.name} className="d-flex align-items-center p-3">
          <img src={logo} style={{maxWidth: "24px", marginRight: '20px'}}/> {props.label}
          {
            props.name === 'intangibleValues'
            &&
            <a href="https://storage.googleapis.com/cotswold-coaching-clinic-assets/intangible-values-list.pdf" target="_blank">
              <DownloadIcon />
              Intangible Values
            </a>
          }
        </Label>
        {question}
      </FormGroup>
    </React.Fragment>
  )
}

export default Question;