import React from 'react';
import {Container, Row, Col, Card, CardBody, CardTitle, CardText, Button} from "reactstrap";
import SectionTitleRow from "../shared/SectionTitleRow";
import Divider from "../shared/Divider";
import SubtitleRow from "../shared/SubtitleRow";

import scrollToAnchor from "../utilities/scrollToAnchor";

import closingGenderPayGap from "../../static/images/gender-pay-gap-image.png";
import wellbeingInOffice from "../../static/images/workplace-well-being.jpg";
import spa from "../../static/images/spa.jpeg";
import sercoLogo from "../../static/images/serco_v2_logo_rgb.png";
import psiLogo from "../../static/images/psi-logo-2.png";
import gcuLogo from "../../static/images/gcu_logo.jpg";
import Spacer from "../shared/Spacer";
import Quote from "../shared/Quote";

const CorporateServices = () => {
  return (
    <Container>
      <a id="anchorCorporateServices" />
      <Spacer />
      <SectionTitleRow>Corporate services</SectionTitleRow>
      <Row>
        <div className="my-3"> </div>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <Card>
            <div>
              <img
                className="w-100"
                src={closingGenderPayGap}
                alt="Graphic of man running up steps while woman has to climb a cliff"
              />
            </div>
            <CardBody className="text-center">
              <CardTitle tag="h4">
                Closing the gender pay gap
              </CardTitle>
              <CardText>
                <Button onClick={() => scrollToAnchor('genderPayGap')}>
                  Read more
                </Button>
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card>
            <div>
              <img
                className="w-100"
                src={wellbeingInOffice}
                alt="Photo of office workers having fun with chairs"
              />
            </div>
            <CardBody className="text-center">
              <CardTitle tag="h4">
                Well-being in the workplace
              </CardTitle>
              <CardText>
                <Button onClick={() => scrollToAnchor('workplaceWellBeing')}>
                  Read more
                </Button>
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card>
            <div>
              <img
                className="w-100"
                src={spa}
                alt=""
              />
            </div>
            <CardBody className="text-center">
              <CardTitle tag="h4">
                Life coaching in hospitality
              </CardTitle>
              <CardText>
                <Button onClick={() => scrollToAnchor('coachingInSpas')}>
                  Read more
                </Button>
              </CardText>
            </CardBody>
          </Card>
        </Col>

      </Row>

      <Divider />

      <a className="ccc-anchor" id="genderPayGap" />
      <Spacer />
      <SubtitleRow>Closing the gender pay gap</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            In 2019 the Office for National Statistics reported that 75.1% of mothers with dependent children were in work.
            Although this percentage has gradually increased over the last two decades, many working mothers continue to struggle with their career progression, feeling the need to work harder to prove themselves.
            As a result, it’s not unusual for women to retrain after starting a family in order to achieve the flexibility they want and the well-being they deserve.
          </p>
          <p>
            Family-friendly organisations do not just offer flexi-time and childcare schemes; they invest in their working mothers to enhance performance and progression, promote inclusion and well-being, and reduce their gender pay gap.
            Diverse group coaching of working mothers can achieve this and I offer this service through my bespoke programme <em>Revive and Thrive Together</em>.
          </p>
          <p style={{fontWeight: 'bold'}}>
            I also deliver a 3-hour ‘taster’ workshop for <em>Revive and Thrive Together</em>.
            This covers some of the basic elements of the full programme and introduces participants to the practice of coaching.
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Quote
            content={
              <React.Fragment>
                <p>“I worked with Sally when I joined <em>Revive and Thrive Together</em>, her group coaching programme for professional working mothers.</p>
                <p>
                  She expertly led the group coaching sessions, identifying well with our challenges and engaging us to think more deeply about our needs, values, and strengths.
                  Sally had an amazing ability to help us see where we were restricting ourselves.
                  She empowered us to step out of our comfort zone and her kind strictness motivated us to keep going.
                  She knew when to probe and when to hold back.
                  She never gave up and found different ways to support us.
                </p>
                <p>
                  I truly believe that my coaching success is down to Sally’s ability to effortlessly tease things out.
                  I now understand myself better and I know what I want from life.
                  As a result I plan more, take more control and have a better work-life balance whilst continuing to progress my career.
                </p>
                <p>
                  Sally’s wealth of knowledge, intuition and friendly persona is what makes her coaching so impactful.
                  Even within a group setting she focuses entirely on you as an individual.
                  Having transformed her own life she is a positive role model for women and I have no hesitation in recommending Sally for coaching!”
                </p>
              </React.Fragment>
            }
            attribution="Dr Julie Roberts, Senior Lecturer, Glasgow Caledonian University"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <p>
            Contact me directly at <strong>sally@cotswoldcoachingclinic.com</strong> if you would like to learn more about the ‘taster’ workshop or the full <em>Revive and Thrive Together</em> programme.
          </p>
          <div className="w-100 my-4 text-center">
            <Button href="http://www.legalwomen.org.uk/sallyhanna.html">
              Read my article on the gender pay gap
            </Button>
          </div>
        </Col>
      </Row>

      <Divider />

      <a id="workplaceWellBeing" />
      <Spacer />
      <SubtitleRow>Well-being in the workplace</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            As a former GP I witnessed first-hand the impact of work-related stress on my patients, and because of this, I am passionate about creating a culture of well-being in the workplace.
            By working with you to understand the needs and desires of individual employees and your organisation as a whole, I can design and deliver bespoke services to improve employee well-being and workplace culture.
            These services may include talks, workshops and/or coaching.
          </p>
          <p>
            My holistic approach to well-being addresses mindset and performance by exploring leadership behaviours.
            These behaviours are relevant to everyone – not just those in leadership roles.
            Employees are leaders of their own lives and by developing their leadership behaviours they can improve their confidence, well-being and engagement.
            Collectively, this enhances the resilience of your organisation in terms of its culture, reputation, retention and productivity.
          </p>
          <Row>
            <Col xs={12}>
              <Quote
                content={
                  <React.Fragment>
                    <p>“Wonderfully positive, very motivating.”</p>
                    <p>“I liked your authenticity and found the session surprisingly informative.”</p>
                    <p>“Very useful resources.”</p>
                    <p>“I really enjoyed the session and found it quite inspiring at a time when I’ve felt a dip in my mental health.”</p>
                    <p>“An interesting and engaging presentation with a very important message.”</p>

                    <p>“The self-esteem and resilience content will be really helpful for my mentee and I going forward.”</p>

                    <p>“The session gave me a much better understanding of how I feel. Whilst colleagues can be beneficial, it's nice to have these conversations with an independent and qualified professional.”</p>
                  </React.Fragment>
                }
                attribution="Attendees at 'Well-being in the workplace' session"
              />
            </Col>
          </Row>
          <p>
            Contact me directly at <strong>sally@cotswoldcoachingclinic.com</strong> if you would like to learn more about how I can help you create a culture of well-being in the workplace.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="w-100 text-center">
            <Button
              href="http://www.legalwomen.org.uk/5csleadershipdevleopment.html"
              className="mt-3"
            >
              Read my article on leadership
            </Button>
          </div>
        </Col>
      </Row>

      <Divider />

      <a id="coachingInSpas" />
      <Spacer />
      <SubtitleRow>Life coaching in hospitality</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p style={{fontWeight: 'bold'}}>
            Are you a hotel or spa manager looking to expand your offerings in an innovative way?
            Would you like your guests to feel good inside and out?
            Do you want to enhance your footfall, revenue and reputation? Life coaching could be the answer!
          </p>
          <p>
            We live in such a fast-paced world that the opportunity for true relaxation is not only limited, but priceless.
            Hotels and spas offer the chance to unwind but guests want more than traditional spa treatments, physical fitness and nutritious food.
            They want to experience the full mind and body rejuvenation that they deserve, and life coaching can help you to complete your holistic offering for guests.
          </p>
          <p>
            Life coaching is a highly personalised and transformative wellness offering.
            It helps you to understand who you really are, what you actually want from life and how to overcome the thoughts that hold you back so you can achieve your goals.
            It is most effective when individuals give themselves the space they need to reflect which is why hotels and spas are perfectly placed to enrich their guests' wellness experience.
          </p>
          <p>
            I often describe life coaching as a preventative medicine because of the significant impact it has on my clients’ well-being.
            Coupled with more traditional spa offerings, life coaching makes individuals feel energised and empowered.
          </p>
          <p>
            The delivery of life coaching can be tailored to meet the needs of both the hotel/spa and your guests, for example:
          </p>
          <ul>
            <li>Face-to-face or virtually</li>
            <li>One-to-one or in groups</li>
            <li>Standalone or as part of a package (including overnight stays or retreats)</li>
            <li>Walk and talk in nature</li>
          </ul>
          <Quote
            content={
              <React.Fragment>
                <p>
                  “I work in the spa and well-being industry and so know how to look after myself physically and spiritually.
                  However, coaching gave me further coping strategies and ways to move forward in challenging work and personal situations.
                  Sally is empathetic with a friendly and professional approach. Having someone impartial to discuss issues with pushed me to unpack things inside of me.
                  It was challenging at times but I enjoyed our sessions, felt supported throughout and came away with practical solutions and tools to deal with future situations.”
                </p>
              </React.Fragment>
            }
          />
          <p>
            Contact me directly at <strong>sally@cotswoldcoachingclinic.com</strong> to learn more about how my life coaching services could complement your current offerings and benefit your guests.
          </p>
        </Col>
      </Row>

      <Divider />

      <SubtitleRow>Previous clients</SubtitleRow>
      <Row>
        <Col xs={12} md={4}>
          <div className="text-center p-4">
            <img src={sercoLogo} className="w-100" alt="Serco logo" />
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="text-center p-4">
            <img src={psiLogo} className="w-100" alt="PSI logo" />
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="text-center p-4">
            <img src={gcuLogo} className="w-100" alt="PSI logo" />
          </div>
        </Col>
      </Row>

      <Divider />

    </Container>
  )
}

export default CorporateServices;