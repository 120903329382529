import { configureStore } from "@reduxjs/toolkit";

import discReducer from "./features/discSlice";
import pageReducer from "./features/pageSlice";
import userReducer from "./features/userSlice";
import wheelOfLifeReducer from "./features/wheelOfLifeSlice";

export const store = configureStore({
  reducer: {
    disc: discReducer,
    page: pageReducer,
    user: userReducer,
    wheelOfLife: wheelOfLifeReducer
  },
})
