import React from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Progress} from "reactstrap";
import {setCurrentQuestionnairePage} from "../../../features/pageSlice";
import Question from "./Question";

const QuestionFinal = () => {

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Col xs={12} className="d-flex justify-content-center">
        <div style={{maxWidth: '800px'}}>
          <div className="text-center">
            6 of 6
          </div>
          <Progress
            max="6"
            value="6"
            barStyle={{backgroundColor:"#c49c50"}}
          />
          <h5 className="mt-3 text-center">Final question</h5>
          <Question
            name="isThereAnythingElse"
            label="Is there anything else I should know about you that could be helpful before we start coaching together? (e.g. previous / current mental health concerns, access needs etc?)."
            id="isThereAnythingElse"
            type="textarea"
          />
          <div className="d-flex justify-content-between">
            <Button
              className="mx-4"
              onClick={() => dispatch(setCurrentQuestionnairePage(5))}
            >
              Prev
            </Button>
            <Button
              onClick={() => dispatch(setCurrentQuestionnairePage(7))}
            >
              Finish
            </Button>
          </div>

        </div>
      </Col>
    </React.Fragment>
  )
}

export default QuestionFinal;