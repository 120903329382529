import React from 'react';
import { Container, Row, Col } from "reactstrap";
import SectionTitleRow from "../shared/SectionTitleRow";

import Spacer from "../shared/Spacer";

const GetInTouch = () => {
  return (
    <Container>
      <a id="getInTouch" />
      <SectionTitleRow>Get in touch</SectionTitleRow>
      <Row>
        <Col xs={12}>
          {/*<p className="w-100 mt-4">*/}
          {/*  Contact me at <a style={{fontWeight: 'bold', color: '#00122e' }} href="mailto:sally@cotswoldcoachingclinic.com">sally@cotswoldcoachingclinic.com</a> to arrange a complimentary no-obligation chat to explore whether coaching is right for you at this time.*/}
          {/*</p>*/}
          <p className="w-100">
            Coaching works because of the strong relationship that builds between you and your coach.
          </p>
          <p className="w-100 mb-4">
            My service to you is personal, private and confidential from start to finish.
            You will deal directly with me – not a PA or a VA or an automated calendar system.
          </p>
          <iframe
            id="JotFormIFrame-230355543074048"
            title="Contact Form"
            //onLoad="window.parent.scrollTo(0,0)"
            //allowTransparency="true"
            //allowFullScreen="true"
            //allow="geolocation; microphone; camera"
            src="https://form.jotform.com/230355543074048"
            frameBorder="0"
            style={{
              minWidth: '100%',
              height: '700px',
              border: 'none'
            }}
            scrolling="no"
          >
          </iframe>
        </Col>
      </Row>
      <Spacer />

    </Container>
  )
}

export default GetInTouch;