import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Button, Input } from "reactstrap";

import { setName, setScore} from "../../../features/wheelOfLifeSlice";
import { wheelOfLifeScoreOptions } from "../../../config";


const WheelOfLifeCategoryInput = props => {
  const dispatch = useDispatch();
  const wheelOfLife = useSelector((state) => state.wheelOfLife);

  const [categoryName, setCategoryName] = useState(props.item.name);
  const [categoryValue, setCategoryValue] = useState(props.item.score);

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
    dispatch(setName({id: props.item.id, name: event.target.value}));
  };

  const handleCategoryScoreChange = scoreValue => {
    setCategoryValue(Number(scoreValue));
    dispatch(setScore({id: props.item.id, score: scoreValue}));
  };

  const getButtonClassName = scoreOption => {
    if (props.item.score === scoreOption)
      return 'btn-sm btn-active';
    else
      return 'btn-sm btn-primary';
  }

  if (wheelOfLife === undefined)
    return (
      <p>Loading</p>
    )

  return (
    <FormGroup row className="d-flex justify-content-between">
      <Col xs={12} md={2} className="p-1">
        <Input
          type="text"
          id="textInput"
          onChange={handleCategoryNameChange}
          value={categoryName}
        />
      </Col>
      <Col xs={12} md={10} className="p-1 text-center">
        {
          wheelOfLifeScoreOptions.map((scoreOption) => {
            return (
              <Button
                className={getButtonClassName(scoreOption)}
                style={{marginRight:'7px', marginBottom: '7px'}}
                key={scoreOption}
                onClick={() => handleCategoryScoreChange(scoreOption)}
              >
                {scoreOption}
              </Button>
            )
          })
        }
      </Col>
    </FormGroup>
  )
}

export default WheelOfLifeCategoryInput;