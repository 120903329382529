import React from 'react';
import { Row, Col } from "reactstrap";

import butterfly from "../../static/images/butterfly-blue.png";

const TestimonialRow = props => {
  return (
    <Row>
      <Col xs={12}>
        <div className="mt-3 mb-3 ccc-emphasis-box">
          <div style={{
            textAlign: 'center',
            fontSize: '1.25em'
          }}>
            {props.children}
          </div>
          <div className="text-end fst-italic">
            {props.attribution}
          </div>
          <div className="text-center w-100 mt-3">
            <img src={butterfly} style={{maxHeight: '48px'}} alt="Butterfly logo" />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default TestimonialRow;