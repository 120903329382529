import React from 'react';
import {Card, CardBody, Col, Container, Row, Button} from "reactstrap";
import YouTube from 'react-youtube-embed';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import SectionTitleRow from "../shared/SectionTitleRow";

import creatLogo from "../../static/images/4CreatLogo2.png";
import legalWomenLogo from "../../static/images/legal-women-logo.png";
import nationalDiversityAwardsLogo from "../../static/images/sally-hann-national-diversity-awards-nomination.jpg";
import coachingCastLogo from "../../static/images/coaching-cast.jpg";
import oxfordMenopauseImage from "../../static/images/oxford-menopause.png"
import farncombeZenImage from "../../static/images/boutique-hotelier.png"
import allyshipImage from "../../static/images/allyship.jpg"
import Divider from "../shared/Divider";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const AsFeaturedIn = props => {
  return (
    <Container fluid>
      <SectionTitleRow>As featured in...</SectionTitleRow>

      <Row>
        <Carousel
          autoPlay={true}
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={1750}
        >
            <Card outline={false} className="text-center">
              <CardBody>
                <img src={creatLogo} alt="4H Creat Logo" className="w-100 mb-4" />
                <div className="d-flex align-content-center flex-column">
                  <Button style={{margin:"10px"}} href="caledonian-connected.png">
                    Read about how my group coaching programme helped the creative sector in Scotland
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card outline={false} className="text-center">
              <CardBody>
                <img src={legalWomenLogo} alt="Legal Women Magazine" className="w-100 mb-4" />
                <div className="d-flex align-content-center flex-column">
                  <Button style={{margin:"10px"}} href="http://www.legalwomen.org.uk/sallyhanna.html">
                    Read my article on the gender pay gap
                  </Button>
                  <Button
                    style={{margin:"10px"}}
                    href="http://www.legalwomen.org.uk/5csleadershipdevleopment.html"
                    className="mt-3"
                  >
                    Read my article on leadership
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card outline={false} className="text-center">
              <CardBody>
                <img src={nationalDiversityAwardsLogo} alt="Legal Women Magazine" className="w-100" />
              </CardBody>
            </Card>
            <Card outline={false} className="text-center">
              <CardBody>
                <img src={coachingCastLogo} alt="Legal Women Magazine" className="w-100" />
                <Button
                  href="https://www.youtube.com/watch?v=-AXjCieBPzM&t=496s"
                  className="mt-3"
                >
                  Watch the full episode
                </Button>
              </CardBody>
            </Card>
            <Card outline={false} className="text-center">
              <CardBody>
                <a href="https://www.oxfordmenopause.com/associates/cotswold-coaching-clinic" target="_blank">
                  <img src={oxfordMenopauseImage} alt="Oxford Menopause" className="w-100" />
                </a>
              </CardBody>
            </Card>
            <Card outline={false} className="text-center">
              <CardBody>
                <a href="https://kpmg.com/uk/en/home/about/our-impact/our-people/inclusion--diversity-and-equity/ethnicity.html#black-lives-matter" target="_blank">
                  <img src={allyshipImage} alt="Allyship image" className="w-100 mb-4" />
                </a>
              </CardBody>
            </Card>
            <Card outline={false} className="text-center">
              <CardBody>
                <img src={farncombeZenImage} alt="Farncombe Zen" className="w-100 mb-4" />
                <div className="d-flex align-content-center flex-column">
                  <Button style={{margin:"10px"}} href="https://www.boutiquehotelier.com/farncombe-zen-wellness-hub/">
                    Introducing life coaching as a preventative wellness offering to guests at the Farncombe Estate
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card outline={false} className="text-center">
              <CardBody style={{backgroundColor: '#fbf2d8', border: '20px solid #ffffff'}}>
                <YouTube id='9INqWTyuA2c' />
                <Button
                  href="https://www.youtube.com/watch?v=9INqWTyuA2c"
                  className="mt-3"
                >
                  Watch 'A Christmas Fireside Chat Part 1'
                </Button>
              </CardBody>

            </Card>
            <Card outline={false} className="text-center">
              <CardBody style={{backgroundColor: '#fbf2d8', border: '20px solid #ffffff'}}>
                <YouTube id='dsh2ouBSY_U' />
                <Button
                  href="https://www.youtube.com/watch?v=dsh2ouBSY_U"
                  className="mt-3"
                >
                  Watch 'A Christmas Fireside Chat Part 2'
                </Button>
              </CardBody>
            </Card>
        </Carousel>
      </Row>

      {/*<Row>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody>*/}
      {/*        <img src={creatLogo} alt="4H Creat Logo" className="w-100 mb-4" />*/}
      {/*        <div className="d-flex align-content-center flex-column">*/}
      {/*          <Button style={{margin:"10px"}} href="caledonian-connected.png">*/}
      {/*            Read about how my group coaching programme helped the creative sector in Scotland*/}
      {/*          </Button>*/}
      {/*        </div>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody>*/}
      {/*        <img src={legalWomenLogo} alt="Legal Women Magazine" className="w-100 mb-4" />*/}
      {/*        <div className="d-flex align-content-center flex-column">*/}
      {/*          <Button style={{margin:"10px"}} href="http://www.legalwomen.org.uk/sallyhanna.html">*/}
      {/*            Read my article on the gender pay gap*/}
      {/*          </Button>*/}
      {/*          <Button*/}
      {/*            style={{margin:"10px"}}*/}
      {/*            href="http://www.legalwomen.org.uk/5csleadershipdevleopment.html"*/}
      {/*            className="mt-3"*/}
      {/*          >*/}
      {/*            Read my article on leadership*/}
      {/*          </Button>*/}
      {/*        </div>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody>*/}
      {/*        <img src={nationalDiversityAwardsLogo} alt="Legal Women Magazine" className="w-100" />*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody>*/}
      {/*        <img src={coachingCastLogo} alt="Legal Women Magazine" className="w-100" />*/}
      {/*        <Button*/}
      {/*          href="https://www.youtube.com/watch?v=-AXjCieBPzM&t=496s"*/}
      {/*          className="mt-3"*/}
      {/*        >*/}
      {/*          Watch the full episode*/}
      {/*        </Button>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody>*/}
      {/*        <a href="https://www.oxfordmenopause.com/associates/cotswold-coaching-clinic" target="_blank">*/}
      {/*          <img src={oxfordMenopauseImage} alt="Oxford Menopause" className="w-100" />*/}
      {/*        </a>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody>*/}
      {/*        <a href="https://kpmg.com/uk/en/home/about/our-impact/our-people/inclusion--diversity-and-equity/ethnicity.html#black-lives-matter" target="_blank">*/}
      {/*          <img src={allyshipImage} alt="Allyship image" className="w-100 mb-4" />*/}
      {/*        </a>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody>*/}
      {/*        <img src={farncombeZenImage} alt="Farncombe Zen" className="w-100 mb-4" />*/}
      {/*        <div className="d-flex align-content-center flex-column">*/}
      {/*          <Button style={{margin:"10px"}} href="https://www.boutiquehotelier.com/farncombe-zen-wellness-hub/">*/}
      {/*            Introducing life coaching as a preventative wellness offering to guests at the Farncombe Estate*/}
      {/*          </Button>*/}
      {/*        </div>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody style={{backgroundColor: '#fbf2d8', border: '20px solid #ffffff'}}>*/}
      {/*        <YouTube id='9INqWTyuA2c' />*/}
      {/*        <Button*/}
      {/*          href="https://www.youtube.com/watch?v=9INqWTyuA2c"*/}
      {/*          className="mt-3"*/}
      {/*        >*/}
      {/*          Watch 'A Christmas Fireside Chat Part 1'*/}
      {/*        </Button>*/}
      {/*      </CardBody>*/}

      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col xs={12} md={6} lg={4}>*/}
      {/*    <Card outline={false} className="text-center">*/}
      {/*      <CardBody style={{backgroundColor: '#fbf2d8', border: '20px solid #ffffff'}}>*/}
      {/*        <YouTube id='dsh2ouBSY_U' />*/}
      {/*        <Button*/}
      {/*          href="https://www.youtube.com/watch?v=dsh2ouBSY_U"*/}
      {/*          className="mt-3"*/}
      {/*        >*/}
      {/*          Watch 'A Christmas Fireside Chat Part 2'*/}
      {/*        </Button>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Divider />
    </Container>
  )
}

export default AsFeaturedIn;