import React from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Progress} from "reactstrap";

import {setCurrentQuestionnairePage} from "../../../features/pageSlice";
import {questionsCareer} from "./questions";
import Question from "./Question";


const QuestionsCareer = () => {

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Col xs={12} className="d-flex justify-content-center">
        <div style={{maxWidth: '800px'}}>
          <div className="text-center">
            2 of 6
          </div>
          <Progress
            max="6"
            value="2"
            barStyle={{backgroundColor:"#c49c50"}}
          />
          <h5 className="mt-3 text-center">Your career</h5>
          {
            questionsCareer.map(question => {
              return (
                <Question
                  key={question.id}
                  name={question.name}
                  label={question.label}
                  id={question.id}
                  type={question.type}
                />
              )
            })
          }
          <div className="d-flex justify-content-between">
            <Button
              className="mx-4"
              onClick={() => dispatch(setCurrentQuestionnairePage(1))}
            >
              Prev
            </Button>
            <Button
              onClick={() => dispatch(setCurrentQuestionnairePage(3))}
            >
              Next
            </Button>
          </div>

        </div>
      </Col>
    </React.Fragment>
  )
}

export default QuestionsCareer;