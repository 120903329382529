import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Progress} from "reactstrap";
import {setCurrentQuestionnairePage} from "../../../features/pageSlice";
import Question from "./Question";

import { questionsAboutYou } from "./questions";

const QuestionsAboutYou = () => {

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Col xs={12} className="d-flex justify-content-center">
        <div style={{maxWidth: '800px'}}>
          <div className="text-center">
            1 of 6
          </div>
          <Progress
            max="6"
            value="1"
            barStyle={{backgroundColor:"#c49c50"}}
          />
          <h5 className="mt-3 text-center">About you</h5>
          <Form className="mt-5">
            {
              questionsAboutYou.map(question => {
                return (
                  <Question
                    key={question.id}
                    name={question.name}
                    label={question.label}
                    id={question.id}
                    type={question.type}
                  />
                )
              })
            }
          </Form>
          <div className="d-flex justify-content-between">
            <Button
              className="mx-4"
              onClick={() => dispatch(setCurrentQuestionnairePage(null))}
            >
              Prev
            </Button>
            <Button
              onClick={() => dispatch(setCurrentQuestionnairePage(2))}
            >
              Next
            </Button>
          </div>

        </div>
      </Col>
    </React.Fragment>
  )
}

export default QuestionsAboutYou;