import React from 'react';
import {Col, Row} from "reactstrap";
import {CheckIcon} from "@primer/octicons-react";

const CheckBoxRow = props => {
  return (
    <Row className="py-2">
      <Col xs={4} className="text-end">
        <CheckIcon size={24} fill="#008f51" strokeWidth="4px" />
      </Col>
      <Col xs={8}>
        {props.label}
      </Col>
    </Row>
  )
}

export default CheckBoxRow;