import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  { id: 1, name: 'Partner', score: null },
  { id: 2, name: 'Friends', score: null },
  { id: 3, name: 'Family', score: null },
  { id: 4, name: 'Health', score: null },
  { id: 5, name: 'Money', score: null },
  { id: 6, name: 'Career', score: null },
  { id: 7, name: 'Fun & Leisure', score: null },
  { id: 8, name: 'Personal Growth', score: null },
];


export const wheelOfLifeSlice = createSlice({
  name: 'wheelOfLife',
  initialState,
  reducers: {
    setName: (state, action) => {
      const { id, name } = action.payload;
      const itemIndex = state.findIndex((item) => item.id === id);
      if (itemIndex !== -1) {
        state[itemIndex].name = name;
      }
    },
    setScore: (state, action) => {
      const { id, score } = action.payload;
      const itemIndex = state.findIndex((item) => item.id === id);
      if (itemIndex !== -1) {
        state[itemIndex].score = Number(score);
      }
    },
    restoreWheelOfLifeData: (state, action) => {
      return action.payload;
    }
  }
})

export const { setName, setScore, restoreWheelOfLifeData } = wheelOfLifeSlice.actions;

export default wheelOfLifeSlice.reducer;