import React from 'react';

const Quote = props => {
  return (
    <div className="mt-3 mb-3 ccc-emphasis-box">
      <div style={{
        textAlign: 'center',
        fontWeight: props.small ? 'bold' : 'normal',
        fontSize: props.small ? '0.9em' : '1.25em'
      }}>
        {props.content}
      </div>
      <div style={{
        marginTop: '25px',
        textAlign: 'right',
        marginRight: '30px',
        width: '100%'
      }}>
        {props.attribution}
      </div>
    </div>
  )
}

export default Quote;