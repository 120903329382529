import React from 'react';
import { Container, Row, Col } from "reactstrap";

import SectionTitleRow from "../shared/SectionTitleRow";
import Divider from "../shared/Divider";

import sallyPortrait from "../../static/images/sally-background-6.png";
import coachingAcademyLogo from "../../static/images/tca_accreditedby.jpg";
import mcLeanLogo from "../../static/images/affiliate-ioc-logo.png";

import SubtitleRow from "../shared/SubtitleRow";
import Quote from "../shared/Quote";
import GetInTouchButton from "./GetInTouchButton";

const WhyMe = () => {
  return (
    <Container>
      <a className="ccc-anchor" id="whyMe" />
      <SectionTitleRow>Why me?</SectionTitleRow>
      <SubtitleRow>So why choose me on this journey to support you?</SubtitleRow>
      <Row>
        <Col xs={12} md={6}>
          <img src={sallyPortrait} className="w-100 mb-4" alt="Sally portrait photo" />
        </Col>
        <Col xs={12} md={6}>
          <p>
            I get it.
          </p>
          <p>
            As an unfulfilled mum and doctor I felt stuck, lost and scared.
          </p>
          <p>
            I procrastinated and put others before me.
          </p>
          <p>
            I didn’t believe enough in myself to change and worried what others would think if I tried.
            I too was sceptical of how anyone else could possibly help me.
          </p>
          <p>
            But then enough was enough. I discovered coaching, started my Diploma in Performance Coaching and received coaching from others along the way.
            My only regret is not having known about coaching earlier when I was feeling most trapped and confused.
          </p>
          <p>
            I’ve since helped other women, men and even children to achieve the outcomes they want and can’t wait to help you too.
          </p>
        </Col>
      </Row>

      <SubtitleRow>My story</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            If you’d met me in 2016, you’d have thought my life was great. And it was – <em>on paper</em>.
            I was an experienced GP with an interesting CV reflecting years of hard work and achievements in different specialties and countries.
            I was practising in a friendly, small surgery in the lovely Cotswolds with great colleagues and serving a loyal community of patients.
            I lived just a short drive away with my beautiful young family in a quaint and rural town.
            What’s not to like?
            But something profound was missing and underneath the veneer I was not happy.
          </p>
          <p>
            After having my children and despite reducing my hours, I became increasingly unfulfilled as a GP.
            My work-life balance didn’t reflect my values and continued to deteriorate as the demands and constraints of the NHS increased.
            The impact on my family was palpable and I hadn’t fully appreciated the extent of the bullying my daughter was suffering from at school.
            An intense 14-hour working day was not conducive to deep and meaningful conversations with a 7-year old!
            The bottom line – although I was a loved and highly respected GP, I no longer enjoyed General Practice and I wasn’t the wife and mother I had hoped I would become.
            Surely there was more to life than this?
          </p>
          <p>
            In 2017 I found myself at a crossroads as my surgery, like many other small surgeries in the country, was closing.
            Although I had every intention at the time of continuing to work as a GP locally I decided to have a short sabbatical first.
            However, in this time I experienced life as I had always hoped it could be – quality time with family and friends, watching my children blossom with contentment and space for my own personal reflection.
            I knew there was no way I was returning to my previous life.
            However, due to a rollercoaster ride of emotions including shame, anger, guilt and fear, it took me two years before I finally removed my name from the GP register.
          </p>
          <p>
            The emotional response to the loss of identity that I experienced with leaving my medical career was like that of grief, but like most, I came out the other end stronger and wiser.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Quote
            content="“Let me fall if I must fall. The one I will become will catch me.”"
            attribution="The Baal Shem Tov"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p>
            In late 2018 I discovered my passion and talent for coaching and began my formal and comprehensive retraining with The Coaching Academy to become a professional certified coach.
            I received coaching myself along the way and I used my newly acquired coaching skills to support my own daughter in overcoming the impact of her school bullying.
            As a result, she grew into a happy and confident pre-teen ready to take on the world and my faith in the coaching process was fortified.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className="w-100 d-flex align-content-center justify-content-center my-4">
            <img
              src={coachingAcademyLogo}
              style={{maxWidth: '70%'}}
              alt="Coaching Academy Logo"
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="w-100 d-flex align-content-center justify-content-center my-4">
            <img
              src={mcLeanLogo}
              style={{maxWidth: '70%'}}
              alt="McLean, Affiliate member of Institute of Coaching Logo"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="mt-4">
            In early 2020 I qualified with a distinction and set up my own coaching practice, Cotswold Coaching Clinic, helping other women to achieve their own success and lead fulfilling lives.
            Seeing others overcome their self-doubt and procrastination to avoid the pain of future regrets is humbling and inspiring.
            As an accredited coach and affiliate member of the Institute of Coaching at McLean Hospital, Harvard Medical School affiliate, I am proudly committed to the highest quality of professional coaching.
          </p>
          <p>
            My life finally feels authentic and balanced because I am at peace with myself personally and professionally.
            Knowing who you <em>really are</em> (not who you or society think you <em>should be</em>) and working towards your true potential is liberating and empowering.
          </p>
        </Col>
      </Row>
      <SubtitleRow>Having walked the talk myself I can help you to clarify and implement whatever change you are looking for.</SubtitleRow>

      <GetInTouchButton />

      <Divider />
    </Container>
  )
}

export default WhyMe;