import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentRowIndex: null,
  canContinue: true,
  messages: [],
  currentQuestionnairePage: null,
  questionnaireAnswers: {},
  discTotals: {},
  discSetting: 1,
  wheelOfLifeImageData: "",
  discProfileImageData: ""
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setCurrentRowIndex: (state, action) => {
      state.currentRowIndex = action.payload;
    },
    setDiscTotals: (state, action) => {
      state.discTotals = action.payload;
    },
    setDiscSetting: (state, action) => {
      state.discSetting = action.payload;
    },
    setCanContinue:(state, action) => {
      state.canContinue = action.payload;
    },
    setMessages:(state, action) => {
      state.messages = action.payload;
    },
    setCurrentQuestionnairePage: (state, action) => {
      state.currentQuestionnairePage = action.payload;
    },
    setQuestionnaireAnswer: (state, action) => {
      state.questionnaireAnswers[action.payload.question] = action.payload.answer;
      localStorage.setItem('questionnaireAnswers', JSON.stringify(state.questionnaireAnswers));
    },
    restoreQuestionnaireAnswers:(state, action) => {
      state.questionnaireAnswers = action.payload;
    },
    setWheelOfLifeImageData:(state, action) => {
      state.wheelOfLifeImageData = action.payload;
    },
    setDISCProfileImageData:(state, action) => {
      state.discProfileImageData = action.payload;
    }
  }
})

export const {
  setCurrentRowIndex,
  setDiscTotals,
  setDiscSetting,
  setCurrentQuestionnairePage,
  setQuestionnaireAnswer,
  restoreQuestionnaireAnswers,
  setWheelOfLifeImageData,
  setDISCProfileImageData
} = pageSlice.actions;

export default pageSlice.reducer;