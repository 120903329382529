import React from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { Col, FormGroup, Label, Input, Form, Button} from "reactstrap";

import { setCurrentQuestionnairePage } from "../../../features/pageSlice";
import Question from "./Question";


const QuestionnaireStartPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Col xs={12} className="d-flex justify-content-center">
        <div style={{maxWidth: '800px'}}>
          <p>
            Please answer as many questions as you feel comfortable with. All the information you provide is strictly confidential.
          </p>
          <p>
            Being as honest as possible helps you to benefit much more from the coaching process.
          </p>
          {/*<p>*/}
          {/*  Please check that you enter your email address accurately.*/}
          {/*  Also, you may prefer to use a personal email for privacy instead of a work email.*/}
          {/*</p>*/}
          {/*<Form className="mt-5">*/}
          {/*  <Question*/}
          {/*    name="email"*/}
          {/*    label="Email"*/}
          {/*    id="email"*/}
          {/*    type="email"*/}
          {/*  />*/}
          {/*</Form>*/}
        </div>
      </Col>
      <Col sm={12} className="text-center mb-4">
        <Button
          className="mx-4"
          onClick={() => navigate('/disc')}
        >
          Prev
        </Button>
        <Button
          onClick={() => dispatch(setCurrentQuestionnairePage(1))}
        >
          Start Questionnaire
        </Button>
      </Col>
    </React.Fragment>
  )
}

export default QuestionnaireStartPage;


        // href="https://docs.google.com/forms/d/e/1FAIpQLSdKACgicnuVWCBvzeiZPO_qDdpKxg9ViUARyvViCppXNrANlQ/viewform?usp=sf_link"