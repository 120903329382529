import React, {useState} from 'react';
import {Button, Col, Input, Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentRowIndex, setDiscSetting } from "../../../features/pageSlice";
import {useNavigate} from "react-router-dom";

const StartPage = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedSetting = useSelector((state) => state.page.discSetting);
  const [ setting, setSetting ] = useState(savedSetting);

  const handleSettingChange = e => {
    const selectedSetting = Number(e.target.value);
    dispatch(setDiscSetting(selectedSetting));
  }

  return (
    <Row>
      <Col sm={12} className="d-flex justify-content-center">
        <div style={{maxWidth:'800px'}}>
          <p>
            DISC is a widely used behavioural profiling method which helps people to better understand themselves and those they interact with.
            This abbreviated DISC assessment (provided by The Coaching Academy) will calculate a DISC profile for you.
          </p>
          <h5 className="text-center">How do I do this?</h5>
          <p>
            You will see nine pages of four words.
            Using the numbers 1, 2, 3 and 4 you will rank each word according to how well it represents you.
          </p>
          <p>
            Complete this task using your immediate reaction as this is more likely to be an accurate reflection.
          </p>
          <p>
            For consistency think of the same setting (work, home or social) when ranking all words.
          </p>
        </div>
      </Col>
      <Col sm={12} className="d-flex flex-column align-items-center">
        <p>
          Choose one setting:
        </p>
        <Input type="select" style={{maxWidth:"50%"}} onChange={handleSettingChange} defaultValue={setting}>
          <option value={1} key={1}>
            Choose...
          </option>
          <option value={2} key={2}>
            Home
          </option>
          <option value={3} key={3}>
            Work
          </option>
          <option value={4} key={4}>
            Social
          </option>
        </Input>

      </Col>
      <Col sm={12} className="text-center mt-4">
        <p>
          <strong>To benefit from this process it is best to choose the setting in which you feel most challenged in life at the moment.</strong>
        </p>
      </Col>
      <Col sm={12} className="d-flex justify-content-center">
        <Button
          className="mx-4 btn-block btn-primary"
          onClick={() => navigate('/wheeloflife')}
        >
          Back
        </Button>
        <Button
          className={setting === 1 ? "btn-block btn-primary btn-disabled" : "btn-block btn-primary"}
          onClick={() => dispatch(setCurrentRowIndex(0))}
        >
          Start DISC assessment
        </Button>
      </Col>
    </Row>
  )
}

export default StartPage;