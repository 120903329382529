import React from 'react';
import {Col, Container, Row} from "reactstrap";
import SectionTitleRow from "../shared/SectionTitleRow";

import sallyPicture from "../../static/images/sally-picture-top-section.jpg";
import Quote from "../shared/Quote";
import Divider from "../shared/Divider";

const MyMission = () => {
  return (
    <Container>
      <a id="anchorMyMission" />
      <SectionTitleRow>My mission</SectionTitleRow>
      <Row>
        <Col xs={12} md={6}>
          <img
            src={sallyPicture}
            className="w-100 mb-4"
            alt="Portrait photo of Sally Hanna"
          />
        </Col>
        <Col xs={12} md={6}>
          <p>
            My mission with Cotswold Coaching Clinic is to provide a safe haven for women who want to redesign their lives and equip themselves to live and work <em>on their terms</em>.
          </p>
          <p>
            As women, we are all unique and at different stages in our personal and professional lives.
            A successful career and family life means different things to each one of us.
          </p>
          <p>
            I respect every woman’s vision of success and I am passionate about helping you achieve this without compromising your family or your well-being.
          </p>
          <p>
            You deserve to experience the buzz of positive change as I have and you are the reason I have set up my practice.
          </p>
          <Quote
            content="“When you’ve worked hard, and done well, and walked through that doorway of opportunity, you do not slam it shut behind you.”"
            attribution="Michelle Obama"
          />
        </Col>
      </Row>
      <Divider />
    </Container>
  )
}

export default MyMission;