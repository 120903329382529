import React from 'react';
import {Col, Row} from "reactstrap";

const SectionTitleRow = props => {
  return (
    <Row>
      <Col xs={12}>
        <div style={{paddingTop: "70px"}}>
          <h2 className="w-100 text-center my-4">
            {props.children}
          </h2>
        </div>
      </Col>
    </Row>
  )
}

export default SectionTitleRow;