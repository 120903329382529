import React, { useEffect, useState } from 'react';
import { Row, Col } from "reactstrap";
import { setRowData } from "../../../features/discSlice";
import { useDispatch, useSelector } from "react-redux";

const RatingSelector = props => {
  const dispatch = useDispatch();
  const rowData = useSelector((state) => state.disc);

  const [ selectedValue, setSelectedValue ] = useState("")

  const getTotalIndex = (currentRowIndex, wordIndex) => {
    return (currentRowIndex * 4) + wordIndex;
  }

  useEffect(() => {
    let index = getTotalIndex(props.currentRowIndex, props.wordIndex);

    if (rowData[index] === undefined)
      setSelectedValue("")
    else
      setSelectedValue(rowData[index])

  }, [props.currentRowIndex, rowData])


  const updateRating = async e => {
    let index = getTotalIndex(props.currentRowIndex, props.wordIndex);

    await dispatch(setRowData({
      word: index,
      value: e.target.value
    }));
  }

  return (
    <Row key={props.wordIndex}>
      <Col xs={6} sm={4} md={5}>
        <div className="my-1">
          <div className="d-flex justify-content-end">
            {props.name}
          </div>
        </div>
      </Col>
      <Col xs={4} sm={3} md={2}>
        <div className="my-1">
          <select
            className="form-control"
            onChange={updateRating}
            value={selectedValue}
          >
            <option value="">Select...</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
      </Col>
      <Col xs={2} sm={3} md={2}/>
    </Row>
  )
}

export default RatingSelector;