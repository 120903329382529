import React from 'react';
import { Container, Row, Col } from "reactstrap";
import SectionTitleRow from "../shared/SectionTitleRow";
import Divider from "../shared/Divider";
import SubtitleRow from "../shared/SubtitleRow";
import GoToTestimonialsButton from "./GoToTestimonialsButton";

const FrequentlyAskedQuestions = () => {
  return (
    <Container>
      <a id="faqs" />
      <SectionTitleRow>Frequently asked questions</SectionTitleRow>

      <SubtitleRow>Do you only coach women?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            I have a particular interest in coaching women, but I have also coached men (and even children) – a bit like a GP with a special interest in Women’s Health who is qualified to see and help all of their other patients as well.
          </p>
          <p>
            The key to effective coaching is the relationship itself.
            So as long as we have a great connection and we both feel that you would benefit from my service then I am happy to coach you.
          </p>
        </Col>
      </Row>

      <SubtitleRow>Is coaching right for me, my employee or my child?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Quality of life is enhanced when we achieve fulfilling goals in our personal and professional lives. However, there does have to be a commitment on your part if coaching is to be effective:
          </p>
          <ul>
            <li>You must be voluntarily <em>willing</em> to be coached (not forced or expected).</li>
            <li>You must be <em>ready now</em> to start taking the actions that will move you forward.</li>
            <li>You must be able to <em>tolerate the uncertainty</em> that may come with change.</li>
          </ul>
          <p>
            Occasionally coaching is not suitable, for instance:
          </p>
          <ul>
            <li>If you are experiencing <em>current</em> signs and symptoms of an <em>untreated</em> mental health problem.</li>
            <li>If your employee is being held back by a lack of training rather than their perceptions and beliefs.</li>
            <li>If your child is not yet academically or emotionally able to engage with the coaching process.</li>
          </ul>
          <p>
            If you can satisfy these criteria, it is never too early or too late to benefit from performance coaching for success.
          </p>
        </Col>
      </Row>

      <SubtitleRow>Will you advise me on what I should/need to do?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>No.</p>
          <p>
            Coaching is different to mentoring, consulting and therapy in that it is not an advisory or medical service.
            Only you know what is best for your life and as you are the expert in ‘you’, it is your responsibility to choose and implement the actions towards change.
          </p>
          <p>
            My role is to create a safe space for you to be honest with yourself, provide structured support using reliable tools and strategies, compassionately challenge your thinking to unlock possibility and hold you to account so you keep moving forward with your self-discovery and self-improvement.
          </p>
        </Col>
      </Row>

      <SubtitleRow>What if I want to focus on personal rather than professional goals?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            It is entirely up to you what you decide to focus on in your sessions. Your personal and professional performance is closely linked in that improving how you show up in your private life will have a positive effect on your work life and vice versa. Helping you to get ahead in either area of your life will contribute to your overall success.
          </p>
          <p>
            In practice, the interdependency of both areas of your life means it is difficult to focus on one without considering the other and so most clients choose to work with both personal and professional goals during the course of their coaching with me.
          </p>
        </Col>
      </Row>

      <SubtitleRow>Could I get upset during sessions?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Coaching focuses on your current strengths and future success – it is a positive and forward-thinking approach and does not focus on your past. Some of the life-changing insights you gain about yourself in sessions may at times make you feel emotional and this is normal. However, the positive outcomes of coaching always outweigh any short-lived negative emotions that you might experience.
          </p>
        </Col>
      </Row>

      <SubtitleRow>How are you different to other coaches?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Having worked as a doctor for 17 years, my coaching practice is built on a foundation of integrity, strict confidentiality and putting the client’s needs first.
          </p>
          <p>
            By radically transforming my own life I understand what women (especially mothers) need, not only to reach their potential, but also to sustain it.
          </p>
          <p>
            I will have time for you, want the best for you and will always speak the truth to you.
            Our relationship will be collaborative, based on mutual trust, respect and our shared goal of achieving the change <em>you want</em> for yourself.
          </p>
        </Col>
      </Row>

      <GoToTestimonialsButton />

      <SubtitleRow>What if my goals change during the programme?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            It’s actually quite common for your goals to change (either slightly or drastically) as you learn more about yourself, what you’re capable of and what you really want.
            This is not a bad thing and I continue to support you through any changes you make whilst also challenging you so that you have the self-awareness you need to create the right path for you.
          </p>
          <p>
            If as a result of changing direction you complete the programme before reaching your desired outcome, you may find that you’ve already gained all the strength and insight you need to see you through and that’s great news.
            Alternatively, if you reach the end of the programme before this and would prefer to continue working with me for a little longer, then further individual sessions can be booked depending on what you feel would benefit you.
          </p>
        </Col>
      </Row>

      <SubtitleRow>Can your coaching contribute to my continuing professional development (CPD) training?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Cotswold Coaching Clinic is not a registered CPD provider.
            However, some of my previous clients chose to get approval from their employer for coaching during normal working hours as it is a form of professional development.
            Others didn’t want to disclose their coaching to their employers and chose to be coached in the evenings or at weekends.
          </p>
        </Col>
      </Row>

      <SubtitleRow>What else can you tell me that would convince me to work with you?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            As you progress in your coaching journey with me you will become more aware, creative, decisive, confident and resilient.
            As a result you will feel more relaxed about what the future holds for you.
            Those around you will also notice positive changes in how you show up every day.
            The tools and strategies that I share and use with you can be used time and time again in the future so that you are always in control.
            The good habits that you build not only last the test of time, but can also be modelled by your children.
          </p>
          <p>
            However, coaching is not effective if you do not possess a real desire for change or the emotional courage to confront your vulnerabilities.
            If so, then it’s possible that you are not yet ready to be coached or that you would be better suited to working with an alternative coach.
          </p>
        </Col>
      </Row>

      <SubtitleRow>Do you provide ongoing support after the programme has finished?</SubtitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Some clients choose to continue working with me long-term to sustain their growth.
            Depending on their needs they may book ad-hoc sessions, bi-annual coaching or complete programmes.
          </p>
        </Col>
      </Row>

      <Divider />
    </Container>
  )
}

export default FrequentlyAskedQuestions;