import React, {useState} from "react";
import axios from "axios";
import {Alert, Button, Col, Spinner} from "reactstrap";
import {useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";

import titleImageGarden from "../../../static/images/sally-3.png";
import {INFO_API} from "../../../config";
import {setClientKey} from "../../../features/userSlice";

const ThankYou = () => {
  const navigate = useNavigate();

  axios.defaults.baseURL = 'http://localhost:3000'; // Update the port if needed

  const discData = useSelector((state) => state.page.discTotals);
  const discSetting = useSelector((state) => state.page.discSetting);
  const questionnaireAnswers = useSelector((state) => state.page.questionnaireAnswers);
  const wheelOfLifeData = useSelector((state) => state.wheelOfLife);
  const wheelOfLifeImageData = useSelector((state) => state.page.wheelOfLifeImageData);
  const discProfileImageData = useSelector((state) => state.page.discProfileImageData);
  const clientKey = useSelector((state) => state.user.clientKey);

  const [isSending, setIsSending] = useState(false);
  const [sendResult, setSendResult] = useState(null);

  const send = async () => {

    const clientData = {
      discData,
      discSetting,
      questionnaireAnswers,
      wheelOfLifeData,
      wheelOfLifeImageData,
      discProfileImageData,
      clientKey
    }

    setIsSending(true);

    console.log("Should be sending clientData", clientData)

    try {
      const response = await axios.post(INFO_API, clientData, {
        headers: { 'Content-Type': 'application/json' }
      });
      setSendResult(1);
      setIsSending(false);
      console.log("Information sent - deleting from local storage")
      localStorage.removeItem('wheelOfLife')
      localStorage.removeItem('disc')
      localStorage.removeItem('discTotals')
      localStorage.removeItem('questionnaireAnswers')
    }
    catch (error) {
      console.log("Send error", error)
      setSendResult(-1);
      setIsSending(false);
      console.log("Should have set send result to -1")
    }

    setIsSending(false);

  }

  return (
    <React.Fragment>
      <Col xs={12} className="p-4 d-flex justify-content-center">
        <div style={{maxWidth:'300px'}}>
          <img src={titleImageGarden} className="w-100" alt="Title"/>
        </div>
      </Col>
      <Col xs={12} className="d-flex justify-content-center">
        <div style={{maxWidth: '800px'}}>
          {
            sendResult === null
            &&
            <React.Fragment>
              <p>
                Thank you for making it this far.
                No information has been sent to me yet - to confirm that you are happy for your Wheel of Life, DISC profile, and questionnaire answers to be sent to me, please press the button below.
              </p>
              <p>
                This information will be sent directly to me only, and nobody else will have access to it.
              </p>
              <div className="d-flex justify-content-center">
                <Button
                  className="btn mt-3"
                  style={{backgroundColor: '#f7d490', color: '#000000', fontWeight: 'bold'}}
                  onClick={send}
                >
                  {
                    isSending === true
                      ?
                      <div><Spinner size="sm"/> Sending</div>
                      :
                      <span>Send information</span>
                  }

                </Button>
              </div>
            </React.Fragment>
          }
          {
            sendResult === 1
            &&
            <Alert color="success">
              <p>
                Thank you for completing client onboarding! I will be in touch soon.
              </p>
              {/*<div className="d-flex justify-content-center">*/}
              {/*  <Button*/}
              {/*    className="btn"*/}
              {/*    color="success"*/}
              {/*    onClick={() => navigate('/')}*/}
              {/*  >*/}
              {/*    Cotswold Coaching Clinic Home*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </Alert>
          }
          {
            sendResult === -1
            &&
            <Alert color="danger">
              <p>
                Sorry, a problem occurred while sending
              </p>
              <div className="d-flex justify-content-center">
                <Button
                  className="btn"
                  color="danger"
                  onClick={send}
                >
                  {
                    isSending === true
                      ?
                      <div><Spinner size="sm"/> Sending</div>
                      :
                      <span>Try again</span>
                  }

                </Button>
              </div>
            </Alert>
          }

        </div>
      </Col>
    </React.Fragment>
  )
}

export default ThankYou;