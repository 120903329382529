import React, { useState, useEffect } from 'react';
import axios from "axios";
import {Link, useParams, useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button, Form, Alert} from "reactstrap";
import { useDispatch } from "react-redux";
import { setClientKey } from "../../features/userSlice";
import { restoreWheelOfLifeData } from "../../features/wheelOfLifeSlice";
import { restoreDiscData } from "../../features/discSlice";
import { restoreQuestionnaireAnswers } from "../../features/pageSlice";

import '../../onboarding.css';
import titleImageGarden from "../../static/images/sally-3.png";
import { colours, KEY_API } from "../../config";
import BlueBackground from "../shared/BlueBackground";
import SimpleHeader from "../shared/SimpleHeader";

const Onboarding = () => {
  const dispatch = useDispatch();

  axios.defaults.baseURL = 'http://localhost:3000'; // Update the port if needed

  const { key } = useParams();
  const navigate = useNavigate();
  const [ isValidatingKey, setIsValidatingKey ] = useState(true);
  const [ isKeyValid, setIsKeyValid ] = useState(null);
  const startOnboarding = () => {
    navigate('/wheeloflife')
  }


  const checkKey = async clientKey => {
        // setTimeout(() => {
    //   if (clientID === 'ccc-test')
    //     setIsKeyValid(true);
    //   else
    //     setIsKeyValid(false);
    //   setIsValidatingKey(false)
    // }, 1500)
    const codeData = {
      key: clientKey
    }

    try {
      const response = await axios.post(KEY_API, codeData, {
        headers: { 'Content-Type': 'application/json' }
      });

      console.log("checkKey response > ", response)

      dispatch(setClientKey(clientKey))
      setIsKeyValid(true);
      setIsValidatingKey(false);

      localStorage.setItem('clientKey', clientKey)
      console.log(`Client key ${clientKey} validated`)
    }
    catch (error) {
      console.log("No valid client ID detected - proceeding with anonymous client", error)
      dispatch(setClientKey('anonymous'))
      setIsKeyValid(true);
      setIsValidatingKey(false);
    }

    // If client key valid, restore any data from local storage
    if (localStorage.getItem('clientKey') !== null) {

      if (localStorage.getItem('wheelOfLife') !== null)
        dispatch(restoreWheelOfLifeData(JSON.parse(localStorage.getItem('wheelOfLife'))));

      if (localStorage.getItem('disc') !== null)
        dispatch(restoreDiscData(JSON.parse(localStorage.getItem('disc'))));

      if (localStorage.getItem('questionnaireAnswers') !== null)
        dispatch(restoreQuestionnaireAnswers(JSON.parse(localStorage.getItem('questionnaireAnswers'))));
    }

    // setIsKeyValid(true);
    setIsValidatingKey(false);
  }

  useEffect(() => {
    let clientKey = key;
    if (clientKey === undefined)
      clientKey = localStorage.getItem('clientKey');

    console.log(`Checking client ID ${clientKey}`)
    checkKey(clientKey);
  }, [])

  return (
    <React.Fragment>
      <BlueBackground />
      <Container
        fluid
        style={{backgroundColor: colours.prussianBlue, color: colours.deepChampagne, minHeight: "100%"}}
      >
        <SimpleHeader title="Client onboarding" />
        {
          isValidatingKey === true
          &&
          <Row>
            <Col xs={12} className="text-center loading">
              Validating client ID - please wait a moment
            </Col>
          </Row>
        }

        {
          isKeyValid === true
            &&
            <Row>
              <Row>
                <Col xs={12} className="p-4 d-flex justify-content-center">
                  <div style={{maxWidth:'300px'}}>
                    <img src={titleImageGarden} className="w-100" alt="Title"/>
                  </div>
                </Col>
                <Col xs={12} className="p-4 d-flex justify-content-center">
                  <div style={{maxWidth:'800px'}}>
                    <p>
                      Welcome to Cotswold Coaching Clinic - I'm very much looking forward to working with you.
                    </p>
                    <p>
                      Before we can get started, please follow these steps to let me know more about you.
                      There are three parts to this:
                    </p>
                    <ol>
                      <li><strong>Wheel of Life</strong> - a quick snapshot assessment of where you are today</li>
                      <li><strong>DISC behavioural profile</strong> - to help me understand you better</li>
                      <li><strong>Client questionnaire</strong> - some important questions to start your reflective journey</li>
                    </ol>
                    <Alert color="warning">
                      <h5>Privacy notice</h5>
                      <p>
                        Please note all of your answers will be saved to this device as you go along, so do not use a shared device.
                        This means you can leave and return to the page if you don't want to do it all in one go.
                        At the end, when you send all the information to me, it will be deleted from your device.
                        If you have to use a shared device I can send you documents separately instead - please contact me at <a href={'mailto:sally@cotswoldcoachingclinic.com'}>sally@cotswoldcoachincgclinic.com</a>.
                      </p>
                    </Alert>
                  </div>
                </Col>
                <Col className="text-center">
                  <Form>
                    <Button className="mb-4" onClick={() => {
                      startOnboarding()
                    }}>Let's get started</Button>
                  </Form>
                </Col>
              </Row>
            </Row>
        }

        {
          isKeyValid === false
          &&
          <Row>
            <Row>
              <Col xs={12} className="text-center">
                <div>
                  Sorry, your client ID cannot be validated and may have expired.
                </div>
                <div>
                  Please contact sally@cotswoldcoachingclinic.com
                </div>
              </Col>
            </Row>
          </Row>
        }
      </Container>
    </React.Fragment>
  )
}

export default Onboarding;