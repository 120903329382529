import { createSlice } from "@reduxjs/toolkit";

let discvalues = [];
// for (let i = 0; i < 9; i++) {
//   for (let a = 1; a < 5; a++) {
//     discvalues.push(a.toString());
//   }
// }
const initialState = discvalues;

export const discSlice = createSlice({
  name: 'disc',
  initialState,
  reducers: {
    setRowData: (state, action) => {
      let a = {...state};
      a[action.payload.word] = action.payload.value;
      return a;
    },
    restoreDiscData: (state, action) => {
      return action.payload;
    }
  }
})

export const { setRowData, restoreDiscData } = discSlice.actions;

export default discSlice.reducer;