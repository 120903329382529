import React, { useState, useEffect } from 'react';
import axios from "axios";
import {Link, useParams, useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button, Form, Alert, Progress} from "reactstrap";
import { useDispatch } from "react-redux";
import { setClientKey } from "../../../features/userSlice";
import { restoreWheelOfLifeData } from "../../../features/wheelOfLifeSlice";
import { restoreDiscData } from "../../../features/discSlice";
import {restoreQuestionnaireAnswers, setCurrentQuestionnairePage} from "../../../features/pageSlice";

import '../../../onboarding.css';
import titleImageGarden from "../../../static/images/sally-3.png";
import { colours, KEY_API } from "../../../config";
import BlueBackground from "../../shared/BlueBackground";
import SimpleHeader from "../../shared/SimpleHeader";
import {questionsAboutYou} from "../questionnaire/questions";
import Question from "../questionnaire/Question";

const FeedbackForm = () => {
  const dispatch = useDispatch();

  // axios.defaults.baseURL = 'http://localhost:3000'; // Update the port if needed

  const { key } = useParams();
  const navigate = useNavigate();
  const [ isValidatingKey, setIsValidatingKey ] = useState(true);
  const [ isKeyValid, setIsKeyValid ] = useState(null);
  const startOnboarding = () => {
    navigate('/feedback/form')
  }


  useEffect(() => {
    console.log("Page loaded")

    // Scroll back to top after Google form submitted
    window.addEventListener('message', function(event) {
      if (event.data === 'formSubmitted') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });

  }, [])


  return (
    <React.Fragment>
      <BlueBackground />
      <Container
        fluid
        style={{backgroundColor: colours.prussianBlue, color: colours.deepChampagne, minHeight: "100%"}}
      >
        <Row>
          <Row>
            <Col xs={12} className="p-1 d-flex justify-content-center">
              <div style={{maxWidth:'200px'}}>
                <img src={titleImageGarden} className="w-100" alt="Title"/>
              </div>
            </Col>
            <Col xs={12} className="p-1 d-flex justify-content-center">
              <div>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfWxTykd8WXh55HZw3Wjrbd3zkESCf62UrqTvMfprJnr5hXuw/viewform?embedded=true"
                  width="800" height="4500" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
              </div>
            </Col>

          </Row>
        </Row>

      </Container>
    </React.Fragment>
  )
}

export default FeedbackForm;