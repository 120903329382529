import React, {useState} from 'react';
import {
  Container,
  Collapse,
  Nav,
  UncontrolledDropdown,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarToggler,
  NavbarBrand
} from "reactstrap";

import logo from "../static/images/logo-with-name.svg";
import menu from "../static/images/menu.svg";
import close from "../static/images/close.svg";
import scrollToAnchor from "./utilities/scrollToAnchor";

const Header = () => {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false)

  const toggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const goToPage = id => {
    setIsMenuOpen(false);
    scrollToAnchor(id);
  }

  return (
    <Navbar
        style={{
            backgroundColor: '#0d3661',
            opacity: 0.95
          }}
        expand="lg"
        fixed="top"
      >
        <div className="navbar-brand">
          <Container onClick={() => goToPage('home')}>
            <img src={logo} style={{
              minHeight: "72px"
            }} alt="Cotswold Coaching Clinic Logo" />
          </Container>
        </div>

        <NavbarToggler onClick={toggle}>
          {
            isMenuOpen === true
            ?
            <img src={close} style={{minHeight: "16px", border: 'none', cursor: 'pointer', marginRight: '10px'}} alt="Close menu" />
            :
            <img src={menu} style={{minHeight: "16px", border: 'none', cursor: 'pointer', marginRight: '10px'}} alt="Open menu" />
          }
        </NavbarToggler>

        <Collapse isOpen={isMenuOpen} navbar className="justify-content-end">
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#" onClick={() => goToPage('home')}>
                Home
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                About
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => goToPage('aboutYou')}>
                  About You
                </DropdownItem>
                <DropdownItem onClick={() => goToPage('howICanHelpYou')}>
                  How I can help you
                </DropdownItem>
                <DropdownItem onClick={() => goToPage('whyMe')}>
                  Why me?
                </DropdownItem>
                <DropdownItem onClick={() => goToPage('fees')}>
                  Packages
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem>
              <NavLink href="#" onClick={() => goToPage('selectedTestimonials')}>
                Testimonials
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Corporate Services
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => goToPage('genderPayGap')}>
                  Closing the gender pay gap
                </DropdownItem>
                <DropdownItem onClick={() => goToPage('workplaceWellBeing')}>
                  Well-being in the workplace
                </DropdownItem>
                <DropdownItem onClick={() => goToPage('coachingInSpas')}>
                  Life coaching in hospitality
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem>
              <NavLink href="#" onClick={() => goToPage('faqs')}>
                FAQs
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="#" onClick={() => goToPage('getInTouch')}>
                Contact
              </NavLink>
            </NavItem>

          </Nav>
        </Collapse>
      </Navbar>
  )
}

export default Header;