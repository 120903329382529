import React from 'react';
import {Container, Row, Col, Button} from "reactstrap";

// import titleImage from "../static/images/sally-background-12.png";

import titleImageGarden from "../../static/images/sally-2.jpg";

import Spacer from "../shared/Spacer";
import {colours} from "../../config";
import scrollToAnchor from "../utilities/scrollToAnchor";
import GetInTouchButton from "./GetInTouchButton";
import Quote from "../shared/Quote";

const Title = () => {
  return (
    <Container fluid style={{padding: 0}}>

      <a id="home" />
      <div style={{marginTop:"126px"}} />

      <Row style={{backgroundColor: colours.cornSilk}}>
        <Col xs={12} md={6}>
          <div className="">
            <img src={titleImageGarden} className="w-100" alt="Title"/>
          </div>
        </Col>

        <Col xs={12} md={6}>
          <div
            className="p-4 d-flex h-100 flex-column justify-content-center"

          >
            <div>
              <p style={{fontSize: "1.75em"}}>
                Hi, I’m Dr Sally Hanna, an accredited performance coach and the founder of Cotswold Coaching Clinic.
              </p>
              {/*<p className="mt-2" style={{fontSize: "1.1em"}}>*/}
              {/*  I help professional women who feel stuck in their personal or work life get ahead without sacrificing their family or well-being.*/}
              {/*</p>*/}
              {/*<p className="mt-2" style={{fontSize: "1.1em"}}>*/}
              {/*  After 17 years working in the NHS, I now help professional women who feel stuck in their personal or work life get ahead without sacrificing their relationships or well-being.*/}
              {/*</p>*/}
              <p className="mt-2" style={{fontSize: "1.1em"}}>
                For seventeen years I worked as a doctor in the NHS.
                I now help women who feel stuck or overwhelmed to get ahead in their personal and professional life.
              </p>
            </div>

            <div className="d-flex justify-content-center">
              <Button style={{margin: "10px"}} onClick={() => scrollToAnchor('anchorMyMission')}>
                Individual coaching
              </Button>
              <Button style={{margin: "10px"}} onClick={() => scrollToAnchor('anchorCorporateServices')}>
                Corporate services
              </Button>
            </div>

            <div className="mt-4">
              <Quote
                small
                content="I have been pleasantly surprised by the impact of Sally's coaching. She helped me to recognise the importance of self-care and supported me in developing my confidence and public speaking skills. She provided useful tools, monitored my progress in real time and was always available through emails and messages. The one-to-one sessions allowed me to really open up and understand myself better. It's been an amazing journey and Sally held my hand throughout."
                attribution="Zainab A (One-to-one client)"
              />
              <Quote
                small
                content="Sally creates an environment which is incredibly supportive and full of possibility, all emotions are welcome and encouraged. She is incredibly honest in the most respectful way and uses a multitude of exercises and scientific evidence to enable you to take steps towards the life you want to have. Her generosity and care for each member of the group is so clear to see and goes into everything she does."
                attribution="SM (Group coaching client)"
              />
            </div>

          </div>

        </Col>
      </Row>

    </Container>
  )
}

export default Title;