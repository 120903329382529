import React, {useEffect} from "react";

import '../../../onboarding.css';
import SimpleHeader from "../../shared/SimpleHeader";
import BlueBackground from "../../shared/BlueBackground";
import {colours} from "../../../config";
import {Container} from "reactstrap";
import {useSelector} from "react-redux";

import QuestionnaireStartPage from "./QuestionnaireStartPage";
import QuestionsAboutYou from "./QuestionsAboutYou";
import QuestionsCareer from "./QuestionsCareer";
import QuestionsCoaching from "./QuestionsCoaching";
import QuestionsYourDevelopment from "./QuestionsYourDevelopment";
import QuestionsYourFuture from "./QuestionsYourFuture";
import QuestionFinal from "./QuestionFinal";
import ThankYou from "./ThankYou";

const Questionnaire = () => {
  const currentQuestionnairePage = useSelector((state) => state.page.currentQuestionnairePage);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'instant'});
  }, [currentQuestionnairePage])

  return (
    <React.Fragment>
      <BlueBackground />
      <Container
        fluid
        style={{backgroundColor: colours.prussianBlue, color: colours.deepChampagne, height: "100%"}}
      >
        <SimpleHeader title="Client onboarding questionnaire" />
        { currentQuestionnairePage === null && <QuestionnaireStartPage /> }
        { currentQuestionnairePage === 1 && <QuestionsAboutYou />}
        { currentQuestionnairePage === 2 && <QuestionsCareer />}
        { currentQuestionnairePage === 3 && <QuestionsCoaching />}
        { currentQuestionnairePage === 4 && <QuestionsYourDevelopment />}
        { currentQuestionnairePage === 5 && <QuestionsYourFuture />}
        { currentQuestionnairePage === 6 && <QuestionFinal />}
        { currentQuestionnairePage === 7 && <ThankYou />}
      </Container>
    </React.Fragment>
  )
}

export default Questionnaire;