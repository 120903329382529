import React from "react";

export const questionsAboutYou = [
  {
    name: 'howWouldYouDescribeYourself',
    label: 'How would you describe yourself to someone who has never met you?',
    id: 'howWouldYouDescribeYourself',
    type: 'textarea'
  },
  {
    name: 'howWouldYouScoreYourSelfWorth',
    label: 'How would you score your self-worth? (Belief in your inherent value).',
    id: 'howWouldYouScoreYourSelfWorth',
    type: 'rating'
  },
  {
    name: 'howWouldYouScoreYourSelfBelief',
    label: 'How would you score your self-belief? (Trust and belief in your ability to succeed).',
    id: 'howWouldYouScoreYourSelfBelief',
    type: 'rating'
  },
  {
    name: 'howDoesYourFamilyDescribeYou',
    label: 'How do your family, friends and colleagues describe you? Consider each category separately and, ideally, actually ask them - what do you learn that surprises you?',
    id: 'howDoesYourFamilyDescribeYou',
    type: 'textarea'
  },
  {
    name: 'proudestAchievements',
    label: 'List your proudest achievements in life so far?',
    id: 'proudestAchievements',
    type: 'textarea'
  },
  {
    name: 'intangibleValues',
    label: 'What intangible values are most important to you in life? (You can download suggestions on the right but do think of your own first as your own words will have far more meaning. You may wish to consider separate lists for your personal and professional life).',
    id: 'intangibleValues',
    type: 'textarea'
  },
  {
    name: 'intangibleValuesHonouring',
    label: 'For each of the values you listed above, how well are you living/honouring each of them right now? Please list them with a rating from 0 to 10, where 0 is \'not at all\' and 10 is \'perfectly\')',
    id: 'intangibleValuesHonouring',
    type: 'textarea'
  },
  {
    name: 'whichOfTheValuesWillYouNotCompromise',
    label: (<span>Which of the values listed above will you <em>not</em> compromise?</span>),
    id: 'whichOfTheValuesWillYouNotCompromise',
    type: 'textarea'
  },
  {
    name: 'innerCritic',
    label: 'What situations bring out your inner critic (that voice in your head that judges you)? What does it say to you or about you? How do you respond in that situation?',
    id: 'innerCritic',
    type: 'textarea'
  },
  {
    name: 'whatBringsOutTheBestInYou',
    label: 'What type of circumstances and individuals bring out the best in you?',
    id: 'whatBringsOutTheBestInYou',
    type: 'textarea'
  },
  {
    name: 'whatBringsOutTheWorstInYou',
    label: 'What type of circumstances and individuals bring out the worst in you?',
    id: 'whatBringsOutTheWorstInYou',
    type: 'textarea'
  },
  {
    name: 'changeMindset',
    label: 'Describe a time when you learned to change your mindset in order to adapt and progress.',
    id: 'changeMindset',
    type: 'textarea'
  }
];

export const questionsCareer = [
  {
    name: 'howWouldYouDescribeYourMainRole',
    label: 'How would you describe your main role during the working day to someone who didn\'t know you?',
    id: 'howWouldYouDescribeYourMainRole',
    type: 'textarea'
  },
  {
    name: 'onAGoodDay',
    label: 'On a really good day at work what do you experience?',
    id: 'onAGoodDay',
    type: 'textarea'
  },
  {
    name: 'strengths',
    label: 'What are your strengths? What do you think needs to happen for you to make full use of all of them?',
    id: 'strengths',
    type: 'textarea'
  },
  {
    name: 'workRelatedPerformance',
    label: 'How would you score your work-related performance?',
    id: 'workRelatedPerformance',
    type: 'rating'
  },
  {
    name: 'workRelatedCommitment',
    label: 'How would you score your work-related commitment?',
    id: 'workRelatedCommitment',
    type: 'rating'
  },
  {
    name: 'workRelatedProductivity',
    label: 'How would you score your work-related productivity?',
    id: 'workRelatedProductivity',
    type: 'rating'
  },
  {
    name: 'workRelatedStressLevels',
    label: 'How would you score your work-related stress levels?',
    id: 'workRelatedStressLevels',
    type: 'rating'
  },
  {
    name: 'workLifeBalance',
    label: 'How would you score your work-life balance?',
    id: 'workLifeBalance',
    type: 'rating'
  },
  {
    name: 'workImpact',
    label: 'How is your professional life impacting your personal life, and vice versa?',
    id: 'workImpact',
    type: 'textarea'
  }
];

export const questionsCoaching = [
  {
    name: 'whatDoYouBring',
    label: 'What do you BRING to our coaching relationship that will help you to help yourself? (Consider your knowledge, skills and characteristics).',
    id: 'whatDoYouBring',
    type: 'textarea'
  },
  {
    name: 'whatDoYouNeed',
    label: 'What do you NEED from me as your coach to support your growth and enjoy this journey?',
    id: 'whatDoYouNeed',
    type: 'textarea'
  },
  {
    name: 'whatDoYouWantToBe',
    label: (<span>What do you want to <strong>be</strong> that you don't think you are right now? (Think about what you <em>actually</em> want not what you think you <em>should</em> want).</span>),
    id: 'whatDoYouWantToBe',
    type: 'textarea'
  },
  {
    name: 'whatDoYouWantToBeDoing',
    label: (<span>What do you want to be <strong>doing</strong> that you are not doing right now?</span>),
    id: 'whatDoYouWantToBeDoing',
    type: 'textarea'
  },
  {
    name: 'whatDoYouWantToHave',
    label: (<span>What do you want to <strong>have</strong> that you do not have right now?</span>),
    id: 'whatDoYouWantToHave',
    type: 'textarea'
  },
  {
    name: 'whatDoYourFamilyWant',
    label: 'What changes would your family like to see as a result of coaching? (Ask them to be specific!)',
    id: 'whatDoYourFamilyWant',
    type: 'textarea'
  },
  {
    name: 'WhatDoYouThinkYourFriendsWant',
    label: 'What changes do you think your friends want to see as a result of coaching? (Ask them if possible).',
    id: 'whatDoYouThinkYourFriendsWant',
    type: 'textarea'
  },
  {
    name: 'WhatDoYouThinkYourColleaguesWant',
    label: 'What changes do you think your colleagues might like to see as a result of coaching? (Ask them if possible).',
    id: 'whatDoYouThinkYourColleaguesWant',
    type: 'textarea'
  },
  {
    name: 'yourCommitment',
    label: 'How would you score your commitment to implementing change to achieve what you say you want for yourself?',
    id: 'yourCommitment',
    type: 'rating'
  }
];

export const questionsYourDevelopment = [
  {
    name: 'howDoYouLearnBest',
    label: 'How do you learn best? (For example seeing, doing, reading, listening, or working alone/together?)',
    id: 'howDoYouLearnBest',
    type: 'textarea'
  },
  {
    name: 'howDoYouLikeToBeChallenged',
    label: 'How do you like to be challenged and held to account?',
    id: 'howDoYouLikeToBeChallenged',
    type: 'textarea'
  },
  {
    name: 'whoHasMostInfluencedYou',
    label: 'Who has MOST influenced your personal and professional growth so far? How?',
    id: 'whoHasMostInfluencedYou',
    type: 'textarea'
  },
  {
    name: 'longTermGoals',
    label: 'What are possible long-term goals that excite you and which you might consider working towards?',
    id: 'longTermGoals',
    type: 'textarea'
  },
  {
    name: 'areasToFocusOn',
    label: 'What are the areas you need to be focusing on first to be where you want to be in a year\'s time?',
    id: 'areasToFocusOn',
    type: 'textarea'
  },
  {
    name: 'whatHaveYouAlwaysWantedToAchieve',
    label: 'What have you always wanted to develop or achieve but never had the courage to do? How would doing that now help you in your career and/or personal life?',
    id: 'whatHaveYouAlwaysWantedToAchieve',
    type: 'textarea'
  }
];

export const questionsYourFuture = [
  {
    name: 'whatOpportunities',
    label: 'What potential opportunities lie ahead for you?',
    id: 'whatOpportunities',
    type: 'textarea'
  },
  {
    name: 'whereDoYouNeedToStartSayingNo',
    label: 'Where do you need to start saying \'No\' to be able to start saying \'Yes\' to some of these opportunities?',
    id: 'whereDoYouNeedToStartSayingNo',
    type: 'textarea'
  },
  {
    name: 'whenIHaveFinishedCoaching',
    label: (<span><strong>"When I have finished coaching with Sally, I will be..."</strong> - please complete this with a sentence or a paragraph.</span>),
    id: 'whenIHaveFinishedCoaching',
    type: 'textarea'
  }
]