import { combineReducers } from "@reduxjs/toolkit";

import {discSlice} from "../features/discSlice";
import {userSlice} from "../features/userSlice";
import {pageSlice} from "../features/pageSlice";
import {wheelOfLifeSlice} from "../features/wheelOfLifeSlice";

const rootReducer = combineReducers({
  disc: discSlice,
  page: pageSlice,
  user: userSlice,
  wheelOfLife: wheelOfLifeSlice
})

export default rootReducer
