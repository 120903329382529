import React from 'react';
import { Container, Row, Col } from "reactstrap";

import logo from "../static/images/logo-with-name.svg";
import linkedInLogo from "../static/images/linkedin.png";
import privacyPolicy from "../static/privacy-policy.pdf";

const Footer = () => {
  return (
    <Container fluid style={{backgroundColor: '#0d3661', color: '#ffffff'}}>
      <Row>
        <Col xs={12} lg={5}>
          <div className="p-5">
            <img
              src={logo}
              style={{height: "100px"}}
              alt="Cotswold Coaching Clinic Logo"
            />
            <p>
              Cotswold Coaching Clinic Ltd is a company registered in England No. 12358955
            </p>
            <p>
              ICO Registration No. ZA650927
            </p>
            <p>
              Read our <a href={privacyPolicy}>Privacy Policy</a>
            </p>
            <p>
              For all other enquiries please contact <a href="mailto:sally@cotswoldcoachingclinic.com">sally@cotswoldcoachingclinic.com</a>
            </p>
            <p>
              <a className="footer-link" href="https://www.linkedin.com/in/sally-hanna/">
                <img src={linkedInLogo} style={{maxWidth: '48px'}} alt="Go to my LinkedIn page" />
              </a>
            </p>
          </div>

        </Col>
      </Row>

    </Container>
  )
}

export default Footer;