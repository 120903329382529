import React from 'react';
import { Container, Row, Col } from "reactstrap";

import CheckBoxRow from "../shared/CheckBoxRow";

import SectionTitleRow from "../shared/SectionTitleRow";
import GetInTouchButton from "./GetInTouchButton";
import Divider from "../shared/Divider";

const HowICanHelpYou = () => {
  return (
    <Container>
      <a className="ccc-anchor" id="howICanHelpYou" />
      <SectionTitleRow>How I can help you</SectionTitleRow>
      <Row>
        <Col xs={12}>
          <p>
            Whether, as an unfulfilled woman, you want to...
          </p>
        </Col>
      </Row>
      <CheckBoxRow label="Develop your self-awareness" />
      <CheckBoxRow label="Feel worthy and empowered" />
      <CheckBoxRow label="Leverage your strengths more" />
      <CheckBoxRow label="Enhance your relationships" />
      <CheckBoxRow label="Gain clarity on your ambitions" />
      <CheckBoxRow label="Create momentum in your life" />
      <CheckBoxRow label="Accelerate your career progression" />
      <CheckBoxRow label="Overcome Imposter Syndrome" />
      <CheckBoxRow label="Stop self-sabotaging behaviours" />
      <CheckBoxRow label="Let go of negativity" />
      <CheckBoxRow label="Manage your overwhelm" />
      <CheckBoxRow label="Avoid burnout" />
      <CheckBoxRow label="Have better boundaries" />
      <CheckBoxRow label="Make decisions confidently" />
      <CheckBoxRow label="Achieve work-life harmony" />
      <Row>
        <Col xs={12}>
          <p className="text-center">
            …my holistic and evidence-based approach to coaching is how I will help you to get ahead.
          </p>
        </Col>
      </Row>
      <GetInTouchButton />
      <Divider />
    </Container>
  )
}

export default HowICanHelpYou;