import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceArea} from "recharts";
import { Row, Col, UncontrolledAlert, Button } from "reactstrap";
import { colours, rows } from "../../../config";
import {setDiscTotals} from "../../../features/pageSlice";

const ResultsTable = props => {
  const disc = useSelector(state => state.disc);
  const dispatch = useDispatch();

  const [ words, setWords ] = useState([]);
  const [ totals, setTotals ] = useState([])
  const [ chartData, setChartData ] = useState([]);
  const [ isViewDetailedAnswers, setIsViewDetailedAnswers ] = useState(false);

  const getColumnTotals = ratings => {
    let columnTotals = [];
    for (let i = 0; i < 4; i++) {
      let tempColumnTotal = 0;
      for (let a = 0; a < 9; a++) {
        let tempIndex = i + (a * 4);
        tempColumnTotal += Number(ratings[tempIndex]);
      }
      columnTotals.push(tempColumnTotal);
    }
    return columnTotals;

  }

  const getDescription = rating => {
    if (rating > 33)
      return `Your total score of ${rating} indicates you strongly share the characteristics of this style of behaviour`
    else if (rating < 17)
      return `Your total score of ${rating} indicates that this style is much less dominant`
    else
      return `Your total score of ${rating} is neutral for this style`
  }

  useEffect(() => {
    let tempWords = [];
    let ratings = Object.values(disc);

    for (let i = 0; i < 9; i++) {
      for (let a = 0; a < 4; a++) {
        let tempIndex = (i * 4) + a;
        tempWords.push({
          word: rows[i][a],
          rating: ratings[tempIndex]
        })
      }
    }
    setWords(tempWords);
    setTotals(getColumnTotals(ratings))
  }, [disc])

  useEffect(() => {
    if (totals.length === 0)
      return;

    let newChartData = [
      {
        "name": "Dominance",
        "score": totals[0]
      },
      {
        "name": "Influence",
        "score": totals[1]
      },
      {
        "name": "Steadiness",
        "score": totals[2]
      },
      {
        "name": "Compliance",
        "score": totals[3]
      }
    ];

    dispatch(setDiscTotals(newChartData));
    localStorage.setItem('discTotals', JSON.stringify(newChartData));

    setChartData(newChartData);
  }, [ totals ])

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} className="d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            <BarChart
              width={450}
              height={250}
              data={chartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0,36]} />
              <Tooltip />

              <ReferenceArea y1={0} y2={17} opacity={0.5} fill={colours.cornSilk} />
              <ReferenceArea y1={33} y2={36} opacity={0.5} fill={colours.cornSilk} />
              <Bar dataKey="score" fill={colours.deepChampagne} barSize={32} stroke={colours.deepChampagne} />
            </BarChart>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="p-3">
          <UncontrolledAlert color="info" toggle={null}>
            <h5>Dominance</h5>
            <p style={{color: '#a82d21', fontWeight: '600'}}>{getDescription(totals[0])}</p>
            <p>Behavioural traits:</p>
            <ul>
              <li>Impulsive</li>
              <li>Courageous</li>
              <li>Not easily discouraged</li>
              <li>Work is all consuming</li>
              <li>Excel in emergencies</li>
            </ul>
          </UncontrolledAlert>

        </Col>
        <Col  xs={12} md={6} className="p-3">
          <UncontrolledAlert color="info" toggle={null}>
            <h5>Influence</h5>
            <p style={{color: '#a82d21', fontWeight: '600'}}>{getDescription(totals[1])}</p>
            <p>Behavioural traits:</p>
            <ul>
              <li>Optimistic</li>
              <li>Easily distracted</li>
              <li>Undisciplined</li>
              <li>Charm others</li>
              <li>Enthusiastic</li>
            </ul>
          </UncontrolledAlert>
        </Col>
        <Col xs={12} md={6} className="p-3">
          <UncontrolledAlert color="info" toggle={null}>
            <h5>Steadiness</h5>
            <p style={{color: '#a82d21', fontWeight: '600'}}>{getDescription(totals[2])}</p> <p>Behavioural traits:</p>
            <ul>
              <li>Balanced</li>
              <li>Patient</li>
              <li>Diplomatic</li>
              <li>Steady</li>
              <li>Avoid conflict</li>
            </ul>
          </UncontrolledAlert>
        </Col>
        <Col  xs={12} md={6} className="p-3">
          <UncontrolledAlert color="info" toggle={null}>
            <h5>Compliance</h5>
            <p style={{color: '#a82d21', fontWeight: '600'}}>{getDescription(totals[3])}</p> <p>Behavioural traits:</p>
            <ul>
              <li>Perfectionist</li>
              <li>Organised</li>
              <li>Detail orientated</li>
              <li>Analytical</li>
            </ul>
          </UncontrolledAlert>
        </Col>
      </Row>
      <Row>
        <hr />
        <Col sm={12} className="d-flex justify-content-center">
          {
            isViewDetailedAnswers === true
              ?
              <h5 className="text-center">Your answers and column totals</h5>
              :
              <Button className="btn btn-secondary mb-5" onClick={() => setIsViewDetailedAnswers(true)}>
                View details
              </Button>
          }
        </Col>
        {
          isViewDetailedAnswers === true
          &&
          <React.Fragment>
            {
              words.map((word, index) =>
                <Col xs={3} className="d-flex flex-column align-content-center" key={index}>
                  <div>{word.word}</div>
                  <div>{word.rating}</div>
                </Col>
              )
            }
            <hr />
            {
              totals.map((total, index) =>
                <Col xs={3} style={{ fontWeight: 'bold' }} key={index}>
                  {total}
                </Col>
              )
            }
          </React.Fragment>
        }

      </Row>
    </React.Fragment>

  )
}

export default ResultsTable;