import React from "react";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import logo from "../../static/images/logo.png";
import SubtitleRow from "./SubtitleRow";

const SimpleHeader = props => {
  return (
    <React.Fragment>
      <Row>
        <Col x={12}>
          <div className="mt-3 text-center">
            <img src={logo} style={{maxWidth: "32px"}}/>
          </div>
        </Col>
      </Row>
      <SubtitleRow>{props.title}</SubtitleRow>
    </React.Fragment>
  )
}

export default SimpleHeader;