import React from 'react';
import {Button, Col, Row} from "reactstrap";
import {Scrollchor} from "react-scrollchor";
import scrollToAnchor from "../utilities/scrollToAnchor";
import {MailIcon} from "@primer/octicons-react";

const GoToTestimonialsButton = () => {

  return (
    <Row>
      <Col xs={12} className="text-center">
        <Button className="d-inline-flex align-content-center" onClick={() => scrollToAnchor('selectedTestimonials')}>
          <div className="d-flex justify-content-between align-items-center">
            <MailIcon />
            <div style={{marginLeft: '14px'}} className="ml-3">Read testimonials</div>
          </div>
        </Button>
      </Col>
    </Row>

    //
    // <Row>
    //   <Col xs={12} className="text-center">
    //     <Button className="d-inline-flex align-content-center">
    //       <Scrollchor to="selectedTestimonials" className="ccc-button">
    //         Read testimonials
    //       </Scrollchor>
    //     </Button>
    //   </Col>
    // </Row>
  )
}

export default GoToTestimonialsButton;