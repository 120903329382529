import React from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import { Button, Row, Col } from "reactstrap";

import ResultsTable from "./ResultsTable";
import BlueBackground from "../../shared/BlueBackground";
import {setCurrentRowIndex, setDISCProfileImageData} from "../../../features/pageSlice";

const Results = () => {
  const dispatch = useDispatch();
  const disc = useSelector(state => state.disc)
  const navigate = useNavigate();

  const saveAndGoToCoachingQuestionnaire = () => {
    localStorage.setItem('disc', JSON.stringify(disc))

    const svgElement = document.querySelector('.recharts-surface');

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);
    const base64 = btoa(svgString)
    const dataURL = `data:image/svg+xml;base64,${base64}`;

    console.log(dataURL);

    dispatch(setDISCProfileImageData(dataURL));

    navigate('/questionnaire')
  }

  return (
    <React.Fragment>
      <BlueBackground />
      <Row>
        <Col xs={12}>
          <h5 className="text-center">Results</h5>
          <ResultsTable />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="text-center">
          <p>
            Thank you for completing the DISC profile.
            For the last step, please now complete your coaching questionnaire.
          </p>
        </Col>
        <Col sm={12} className="mb-4 text-center">
          <Button
            className="mx-4"
            onClick={() => dispatch(setCurrentRowIndex(null))}
          >
            Start DISC profile again
          </Button>
          <Button
            onClick={saveAndGoToCoachingQuestionnaire}
          >
            Save and go to coaching questionnaire
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Results;

// href="https://docs.google.com/forms/d/e/1FAIpQLSdKACgicnuVWCBvzeiZPO_qDdpKxg9ViUARyvViCppXNrANlQ/viewform?usp=sf_link"