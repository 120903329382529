import React from 'react';
import {Button, Col, Row} from "reactstrap";

import {MailIcon} from "@primer/octicons-react";
import scrollToAnchor from "../utilities/scrollToAnchor";

const GetInTouchButton = () => {

  return (
    <Row>
      <Col xs={12} className="text-center">
        <Button className="d-inline-flex align-content-center" onClick={() => scrollToAnchor('getInTouch')}>
          <div className="d-flex justify-content-between align-items-center">
            <MailIcon />
            <div style={{marginLeft: '14px'}} className="ml-3">Get in touch</div>
          </div>
        </Button>
      </Col>
    </Row>
  )
}

export default GetInTouchButton;