const scrollToAnchor = id => {

  let topMargin = id === 'home' ? 127 : 60;
  window.scrollTo(0, document.getElementById(id).offsetTop - topMargin)


  // // const link = `https://www.cotswoldcoachingclinic.com/#${id}`;
  // // window.location.href = link;
  //
  //
  // let anchorTarget = document.getElementById(id);
  // anchorTarget.scrollIntoView({ behavior: 'smooth' });
  // window.scrollBy(0,-122);
  //
  // if (id === 'anchorHome')
  //   window.scrollBy(0,-244);
}

export default scrollToAnchor;