export const colours = {
  "oxfordBlue": "#00122e",
  "prussianBlue": "#0d3661",
  "cornSilk": "#fbf2d8",
  "deepChampagne": "#f7d490",
  "satinSheenGold": "#c49c50",
  "frenchBistre": "#85714e",
  "fireBrick": "#a82d21",
  "spanishGreen": "#008f51"
}

export const rows = [
  ['Directing', 'Influencing', 'Steady', 'Cautious'],
  ['Self-certain', 'Optimistic', 'Deliberate', 'Restrained'],
  ['Adventurous', 'Enthusiastic', 'Predictable', 'Logical'],
  ['Decisive', 'Open', 'Patient', 'Analytical'],
  ['Daring', 'Impulsive', 'Stable', 'Precise'],
  ['Competitive', 'Persuading', 'Accommodating', 'Curious'],
  ['Assertive', 'Talkative', 'Modest', 'Tactful'],
  ['Experimenting', 'Charming', 'Easy-going', 'Consistent'],
  ['Forceful', 'Sensitive', 'Sincere', 'Perfectionist']
];

export const wheelOfLifeScoreOptions = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const KEY_API = 'https://europe-west2-cotswold-coaching-clinic.cloudfunctions.net/onboarding';
export const INFO_API = 'https://europe-west2-cotswold-coaching-clinic.cloudfunctions.net/onboardinginformation';