import React, { useState, useEffect } from 'react';

import { rows } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { setCurrentRowIndex} from "../../../features/pageSlice";
import RatingSelector from "./RatingSelector";

const RowInput = () => {
  const currentRowIndex = useSelector((state) => state.page.currentRowIndex);
  const disc = useSelector((state) => state.disc);
  const dispatch = useDispatch();
  const [ message, setMessage ] = useState("");

  useEffect(() => {
    setMessage("");
  }, [currentRowIndex])

  const validateAnswers = () => {
    let answers = [];
    for (let i = 0; i < 4; i++) {
      let checkRowIndex = (currentRowIndex * 4) + i;
      answers.push(disc[checkRowIndex])
    }
    return (answers.indexOf('1') !== -1 && answers.indexOf('2') !== -1 && answers.indexOf('3') !== -1 && answers.indexOf('4') !== -1);
  }

  const next = () => {
    if (validateAnswers() === true) {
      setMessage("");
      let nextRow = currentRowIndex < 8 ? currentRowIndex + 1 : 'finished'
      dispatch(setCurrentRowIndex(nextRow));
    }
    else
      setMessage("Please enter a rating 1, 2, 3 or 4 for each word. You cannot use the same rating twice.")
  }

  return (
    <React.Fragment>
      <Row >
        <Col sm={12} className="d-flex justify-content-center">
          <h2>
            Question {currentRowIndex + 1} of {rows.length}
          </h2>
        </Col>
        <Col sm={12} className="d-flex justify-content-center">
          <p>
            Rate the four words below from 1 to 4, where <strong>1 = LEAST LIKE YOU</strong> and <strong>4 = MOST LIKE YOU</strong>.
          </p>
        </Col>
      </Row>
      {
        rows[currentRowIndex].map((row, index) => {
          return (
            <RatingSelector
              key={index}
              currentRowIndex={currentRowIndex}
              wordIndex={index}
              name={row}
            />
          )
        })
      }
      <Row>
        <Col xs={6} className="d-flex justify-content-center">
          <div className="p-3">
            {
              currentRowIndex === 0
              &&
              <Button className="btn col-12" onClick={() => dispatch(setCurrentRowIndex(null))}>
                Back
              </Button>
            }
            {
              currentRowIndex > 0
              &&
              <Button className="btn col-12"  onClick={() => dispatch(setCurrentRowIndex(currentRowIndex - 1))}>
                Prev
              </Button>
            }
          </div>
        </Col>
        <Col xs={6} className="d-flex justify-content-center">
          <div className="p-3">
            {
              currentRowIndex < rows.length - 1
              &&
              <Button
                className="btn col-12"
                onClick={next}
              >
                Next
              </Button>
            }
            {
              currentRowIndex === rows.length - 1
              &&
              <Button
                className="btn col-12"
                onClick={next}
              >
                Finish
              </Button>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="d-flex justify-content-center mt-4" style={{color: "#f7d490", fontSize: "0.8em"}}>
            {message}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RowInput;